<template>
  <div>
    <LoginModal
      :show="showModal"
      @close="closeModal"
      @login="handleLogin"
      @register="handleRegister"
    />
  </div>
  <nav class="fixed w-full z-50 nav-blur border-b border-white/5">
    <div class="max-w-7xl mx-auto px-6">
      <div class="flex items-center justify-between h-20">
        <div class="flex items-center gap-3 px-0 py-0">
          <div
            class="flex h-8 w-8 items-center justify-center rounded bg-indigo-600"
          >
            <svg
              class="h-5 w-5 text-white"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
              />
            </svg>
          </div>
          <div>
            <h1 class="text-xl font-bold gradient-text tracking-tight">
              Zadabra
            </h1>
            <p class="text-xs text-gray-400">Magical Insights</p>
          </div>
        </div>
        <div class="flex items-center space-x-10">
          <!-- <a
            v-for="link in navLinks"
            :key="link.href"
            :href="link.href"
            class="text-[15px] text-gray-300 hover:text-indigo-300 transition-colors"
          >
            {{ link.text }}
          </a> -->
          <button
            v-if="!isAuthenticated"
            @click="startTrial()"
            class="bg-indigo-500 hover:bg-indigo-600 px-6 py-2.5 rounded-lg text-white font-medium transition-all duration-200 ease-out transform hover:translate-y-[-1px] hover:shadow-lg hover:shadow-indigo-500/25"
          >
            Start Free Trial
          </button>
          <button
            v-else
            @click="navigateToDashboard()"
            class="bg-indigo-500 hover:bg-indigo-600 px-6 py-2.5 rounded-lg text-white font-medium transition-all duration-200 ease-out transform hover:translate-y-[-1px] hover:shadow-lg hover:shadow-indigo-500/25"
          >
            Dashboard
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import LoginModal from '@/components/LoginModal.vue'

const store = useStore()
const router = useRouter()
const currentUser = computed(() => store.getters['auth/currentUser'])
const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])
const showModal = computed(() => store.getters['auth/showLoginModal'])
const brandName = ref('Zadabra')
const navLinks = ref([
  { href: '#features', text: 'Features' },
  { href: '#pricing', text: 'Pricing' }
])

const startTrial = () => {
  store.dispatch('auth/setLoginModal', true)
}

const handleLogin = async (formData) => {
  try {
    await store.dispatch('auth/login', formData)
    store.dispatch('auth/setLoginModal', false)
  } catch (error) {
    console.error('Login failed:', error)
  }
}

const handleRegister = async (formData) => {
  try {
    await store.dispatch('auth/register', formData)
    store.dispatch('auth/setLoginModal', false)
  } catch (error) {
    console.error('Registration failed:', error)
  }
}

const closeModal = () => {
  store.dispatch('auth/setLoginModal', false)
}

function navigateToDashboard() {
  router.push('/dashboard')
}
</script>