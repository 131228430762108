import { createStore } from 'vuex'
import auth from './modules/auth'
import pricing from './modules/pricing'
import comments from './modules/comments'
import channels from './modules/channels'
import analytics from './modules/analytics'

export default createStore({
  modules: {
    auth,
    pricing,
    comments,
    channels,
    analytics
  }
})
