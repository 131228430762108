<template>
  <nav class="space-y-2 pt-6">
    <router-link
      to="/dashboard"
      class="flex items-center space-x-3 rounded-lg px-2 py-2 text-gray-300 hover:bg-gray-800"
      :class="{ 'bg-gray-800 text-white': $route.path === '/dashboard' }"
    >
      <svg
        class="h-5 w-5"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16m-7 6h7"
        />
      </svg>
      <span>Dashboard</span>
    </router-link>

    <router-link
      to="/channels"
      class="flex items-center space-x-3 rounded-lg px-2 py-2 text-gray-300 hover:bg-gray-800"
      :class="{ 'bg-gray-800 text-white': $route.path === '/channels' }"
    >
      <svg
        class="h-5 w-5"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13 10V3L4 14h7v7l9-11h-7z"
        />
      </svg>
      <span>Channels</span>
    </router-link>

    <router-link
      to="/trends"
      class="flex items-center space-x-3 rounded-lg px-2 py-2 text-gray-300 hover:bg-gray-800"
      :class="{ 'bg-gray-800 text-white': $route.path === '/trends' }"
    >
      <svg
        class="h-5 w-5"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
        />
      </svg>
      <span>Trends</span>
    </router-link>

    <router-link
      to="/team"
      class="flex items-center space-x-3 rounded-lg px-2 py-2 text-gray-300 hover:bg-gray-800"
      :class="{ 'bg-gray-800 text-white': $route.path === '/team' }"
    >
      <svg
        class="h-5 w-5"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
      <span>Team</span>
    </router-link>

    <router-link
      to="/billing"
      class="flex items-center space-x-3 rounded-lg px-2 py-2 text-gray-300 hover:bg-gray-800"
      :class="{ 'bg-gray-800 text-white': $route.path === '/billing' }"
    >
      <svg
        class="h-5 w-5"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
        />
      </svg>
      <span>Billing</span>
    </router-link>
  </nav>
</template>