<template>
  <div
    class="py-24 px-6"
    style="background: linear-gradient(to bottom, #13131f, #0a0a12)"
  >
    <div class="max-w-7xl mx-auto">
      <div class="text-center max-w-2xl mx-auto mb-16">
        <h2 class="text-3xl font-bold mb-6 tracking-tight">
          Monitor Real-Time
          <br /><span class="gradient-text">Social Media Engagement</span>
        </h2>
        <p class="text-gray-300 text-lg leading-relaxed">
          {{ socialDescription }}
        </p>
      </div>

      <div class="grid md:grid-cols-3 gap-8">
        <IntegrationCard
          v-for="platform in socialPlatforms"
          :key="platform.name"
          v-bind="platform"
        />
      </div>
    </div>
  </div>
  <div
    class="py-24 px-6"
    style="background: linear-gradient(to bottom, #13131f, #0a0a12)"
  >
    <div class="max-w-7xl mx-auto">
      <div class="text-center max-w-2xl mx-auto mb-16">
        <h2 class="text-3xl font-bold mb-6 tracking-tight">
          Sync with Your
          <br /><span class="gradient-text">Helpdesk Platforms</span>
        </h2>
        <p class="text-gray-300 text-lg leading-relaxed">
          {{ description }}
        </p>
      </div>

      <div class="grid md:grid-cols-3 gap-8">
        <IntegrationCard
          v-for="platform in platforms"
          :key="platform.name"
          v-bind="platform"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import IntegrationCard from './IntegrationCard.vue'

const socialDescription =
  'Get instant alerts and sentiment analysis from your social media channels.'
const description =
  'Turn support conversations into insights that drive product decisions.'

const socialPlatforms = ref([
  {
    icon: 'https://res.cloudinary.com/mioven/image/upload/v1732753747/facebook.png',
    name: 'Facebook',
    features: ['Live Comment Feed', 'Sentiment Tracking']
  },
  {
    icon: 'https://res.cloudinary.com/mioven/image/upload/v1732753747/instagram.png',
    name: 'Instagram',
    features: ['Live Comment Feed', 'Sentiment Tracking']
  },
  {
    icon: 'https://res.cloudinary.com/mioven/image/upload/v1732753868/video.png',
    name: 'Youtube',
    features: ['Live Comment Feed', 'Sentiment Tracking']
  }
])

const platforms = ref([
  {
    icon: 'https://res.cloudinary.com/mioven/image/upload/v1732754128/opczbo6na6c2owjz9pfr.png',
    name: 'Zendesk',
    features: ['Pull Comments', 'Push Comments']
  },
  {
    icon: 'https://res.cloudinary.com/mioven/image/upload/v1732754180/LsnpQfmSWaFRX3gydJdUD0acK6SdwgHywk5Jb2Nt8MMXiVXsiz1qXkQhNEY0v8ZedQ.png',
    name: 'Freshdesk',
    features: ['Pull Comments', 'Push Comments']
  },
  {
    icon: 'https://res.cloudinary.com/mioven/image/upload/v1732754221/31XCUDmdXEL.png',
    name: 'Zoho Desk',
    features: ['Pull Comments', 'Push Comments']
  }
])
</script>