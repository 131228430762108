<template>
  <div v-if="show" class="fixed mt-12 inset-0 z-50 overflow-y-auto">
    <!-- Modal backdrop -->
    <div
      class="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
      @click="closeModal"
    ></div>

    <!-- Modal content -->
    <div class="flex min-h-screen items-center justify-center p-4">
      <div
        class="relative w-full max-w-md rounded-lg bg-gray-900 p-6 shadow-xl"
      >
        <!-- Close button -->
        <button
          @click="closeModal"
          class="absolute right-4 top-4 text-gray-400 hover:text-white"
        >
          <svg
            class="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <!-- Title -->
        <div class="text-xl text-white">
          {{ isLogin ? 'Login' : 'Start Free Trial' }}
        </div>
        <div class="mt-2 text-sm">
          {{
            isLogin
              ? 'Welcome back'
              : 'Sign up for a 14 day free trial. No Credit Card Required'
          }}
        </div>

        <!-- Login Form -->
        <form
          v-if="isLogin"
          @submit.prevent="handleLogin"
          class="space-y-4 mt-6"
        >
          <div>
            <label class="block text-sm font-medium text-gray-300">Email</label>
            <input
              v-model="loginForm.email"
              type="email"
              required
              class="mt-1 p-1.5 block w-full rounded-md border-gray-700 bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-300"
              >Password</label
            >
            <input
              v-model="loginForm.password"
              type="password"
              required
              class="mt-1 p-1.5 block w-full rounded-md border-gray-700 bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <button
            type="submit"
            class="w-full rounded-md bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Login
          </button>
        </form>

        <!-- Register Form -->
        <form v-else @submit.prevent="handleRegister" class="space-y-4 mt-6">
          <div>
            <label class="block text-sm font-medium text-gray-300"
              >Full Name</label
            >
            <input
              v-model="registerForm.name"
              type="text"
              required
              class="mt-1 p-1.5 block w-full rounded-md border-gray-700 bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-300">Email</label>
            <input
              v-model="registerForm.email"
              type="email"
              required
              class="mt-1 p-1.5 block w-full rounded-md border-gray-700 bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-300"
              >Password</label
            >
            <input
              v-model="registerForm.password"
              type="password"
              required
              class="mt-1 p-1.5 block w-full rounded-md border-gray-700 bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-300"
              >Company</label
            >
            <input
              v-model="registerForm.companyName"
              type="text"
              required
              class="mt-1 p-1.5 block w-full rounded-md border-gray-700 bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <button
            type="submit"
            class="w-full rounded-md bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Sign Up
          </button>
        </form>

        <!-- Switch between login/register -->
        <div class="mt-4 text-gray-300">
          <template v-if="isLogin">
            Don't have an account?
            <span
              @click="setModalState('register')"
              class="text-indigo-400 hover:text-indigo-500 cursor-pointer"
              >Register</span
            >
          </template>
          <template v-else>
            Already have an account?
            <span
              @click="setModalState('login')"
              class="text-indigo-400 hover:text-indigo-500 cursor-pointer"
              >Log In</span
            >
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'

const router = useRouter()
const store = useStore()
const props = defineProps({
  show: {
    type: Boolean,
    required: true
  }
})

const emit = defineEmits(['close', 'login', 'register'])
const isLogin = computed(() => store.getters['auth/isLogin'])

// const isLogin = ref(false)

const loginForm = reactive({
  email: '',
  password: ''
})

const registerForm = reactive({
  name: '',
  email: '',
  password: ''
})

const closeModal = () => {
  emit('close')
}

function setModalState(state) {
  store.dispatch('auth/setIsLogin', state == 'login')
}

const handleLogin = async () => {
  try {
    await store.dispatch('auth/login', loginForm)
    router.push('/dashboard')
  } catch (error) {
    console.error('Registration error:', error)
    await Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message,
      showConfirmButton: true,
      customClass: {
        popup: 'dark-swal',
        confirmButton: 'swal-confirm-btn'
      },
      backdrop: `rgba(20, 18, 24, 0.8)`
    })
  }
}

const handleRegister = async () => {
  try {
    await store.dispatch('auth/register', registerForm)

    router.push('/dashboard')
  } catch (error) {
    console.error('Registration error:', error)
    alert(error)
  }
}
</script>