<template>
  <div class="">
    <div v-if="loading">
      <div class="spinner"></div>
      <!-- <p class="text-sm pt-4 text-indigo-500">
        pulling up your billing details...
      </p> -->
    </div>
    <div v-if="!loading" class="">
      <div class="flex justify-between items-center mb-8">
        <h2 class="text-xl font-semibold text-white">Billing</h2>
        <!-- <button
          @click="manageBilling()"
          class="flex items-center gap-2 px-4 py-2 bg-indigo-600 hover:bg-indigo-500 text-white rounded-lg transition-colors duration-200 font-medium"
        >
          <WalletMinimal class="w-5 h-5" />
          <span>Manage Billing</span>
        </button> -->
      </div>
      <!-- Header Section -->
      <!-- <div class="text-center mb-16">
        <h2 class="text-3xl font-bold text-white sm:text-4xl">
          Choose the perfect plan for your needs
        </h2>
        <p class="mt-4 text-lg text-gray-400">
          Get started with Zadabra today. No credit card required.
        </p>
      </div> -->

      <!-- Pricing Cards Grid -->
      <div class="grid gap-8 lg:grid-cols-3">
        <template v-for="plan in sortedPlans" :key="plan.tier">
          <div
            class="relative rounded-xl bg-gray-900 p-8 shadow-lg"
            :class="[
              plan.isPopular
                ? 'border-2 border-indigo-500'
                : 'border border-gray-800'
            ]"
          >
            <!-- Popular Badge -->
            <div v-if="plan.isPopular" class="absolute -top-4 right-8">
              <span
                class="inline-block rounded-full bg-indigo-500 px-4 py-1 text-sm font-medium text-white"
              >
                Current Plan
              </span>
            </div>

            <div class="space-y-6">
              <!-- Header -->
              <div>
                <h3 class="text-2xl font-bold text-white">{{ plan.tier }}</h3>
                <p class="mt-2 text-gray-400">{{ plan.description }}</p>
              </div>

              <!-- Pricing -->
              <div class="flex items-baseline">
                <span class="text-5xl font-bold text-white"
                  >£{{ plan.price }}</span
                >
                <span class="ml-2 text-gray-400">per {{ plan.period }}</span>
              </div>

              <!-- Features -->
              <ul class="space-y-4">
                <li
                  v-for="(feature, index) in plan.features"
                  :key="index"
                  class="flex items-center"
                >
                  <svg
                    class="h-5 w-5 text-indigo-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span class="ml-3 text-gray-300">{{ feature }}</span>
                </li>
              </ul>

              <!-- CTA Button -->
              <button
                @click="getBillingSession(plan)"
                class="w-full rounded-lg px-6 py-3 text-center font-medium transition-all"
                :class="[
                  plan.isPopular
                    ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                    : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                ]"
              >
                {{ displayPlan(plan) }}
              </button>
            </div>
          </div>
        </template>
      </div>

      <!-- Footer Section -->
      <!-- <div class="mt-12 text-center text-gray-400">
        <p>All plans include 14-day free trial. Cancel anytime.</p>
        <p class="mt-2">
          Need a custom plan?
          <span
            class="text-indigo-400 hover:text-indigo-300 cursor-pointer"
            @click="contactUs"
          >
            Contact us
          </span>
        </p>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import Swal from 'sweetalert2'
import { WalletMinimal } from 'lucide-vue-next'

const store = useStore()
const loading = ref(true)

const showModal = computed(() => store.getters['auth/showLoginModal'])
const user = computed(() => store.getters['auth/currentUser'])

const sortedPlans = computed(() => {
  if (!user.value?.subscription?.plan?.id) {
    return plans.value
  }

  return [...plans.value].sort((a, b) => {
    if (a.id === user.value.subscription.plan.id) return -1
    if (b.id === user.value.subscription.plan.id) return 1
    return 0
  })
})

const plans = ref([
  {
    tier: 'Essential',
    price: '29',
    period: 'month',
    description: 'For individuals getting started with feedback analysis',
    features: [
      'Social Integrations',
      'Manual Sources',
      'Sentiment Analysis',
      'Smart Tags',
      '500 Comments'
    ],
    id: 'price_1QQdDtDHwCPIrB6lNsd65wDk',
    level: 1
  },
  {
    tier: 'Business',
    price: '99',
    period: 'month',
    description: 'For teams ready to scale their feedback insights',
    features: [
      'Social Integrations',
      'Helpdesk Integrations',
      'Manual Sources',
      'Sentiment Analysis',
      'Smart Tags',
      '5000 Comments'
    ],
    isPopular: true,
    id: 'price_1QQdDgDHwCPIrB6lw0Q6UOU6',
    level: 2
  },
  {
    tier: 'Enterprise',
    price: '199',
    period: 'month',
    description: 'For organisations needing scale and control',
    features: [
      'Social Integrations',
      'Helpdesk Integrations',
      'Manual Sources',
      'Sentiment Analysis',
      'Smart Tags',
      '10000 Comments'
    ],
    id: 'price_1QQw2oDHwCPIrB6lR8uWsxjy',
    level: 3
  }
])

const userId = '13f899a6215efb48cb974602a3bbee34'
const displayPlan = (plan) => {
  console.log('Type of plans.value:', typeof plans.value)

  const currentPlan = user.value?.subscription?.plan?.id || ''
  console.log('Current plans:', plans.value) // See all plans
  console.log('User subscription:', user.value?.subscription?.plan?.id) // See what we're looking for

  const filteredPlans = plans.value.filter((plan) => {
    console.log(
      'Comparing plan id:',
      plan.id,
      'with user plan:',
      user.value?.subscription?.plan?.id
    )
    return plan.id === user.value?.subscription?.plan?.id
  })

  let planData = filteredPlans[0]
  console.log('Filtered result:', filteredPlans)
  console.log('Plan data:', planData)

  let CTA = plan?.level < planData?.level ? 'Downgrade To ' : 'Upgrade To '
  return plan.id == currentPlan ? 'Manage Plan' : CTA + plan.tier
}

onMounted(() => {
  getUser()
})

const emit = defineEmits(['select-plan', 'contact'])

const getBillingSession = async (plan) => {
  plan['userId'] = user.value.id

  try {
    const response = await store.dispatch('pricing/getBillingSession', plan)
    window.location.href = response.data.session.url
  } catch (error) {
    console.error('Error:', error)
    await Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message,
      showConfirmButton: true,
      customClass: {
        popup: 'dark-swal',
        confirmButton: 'swal-confirm-btn'
      },
      backdrop: `rgba(20, 18, 24, 0.8)`
    })
  }
}

const manageBilling = async () => {
  let currentPlan = user.value?.subscription?.plan
  currentPlan['userId'] = user.value.id

  try {
    const response = await store.dispatch(
      'pricing/getBillingSession',
      currentPlan
    )
    window.location.href = response.data.session.url
  } catch (error) {
    console.error('Error:', error)
    await Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message,
      showConfirmButton: true,
      customClass: {
        popup: 'dark-swal',
        confirmButton: 'swal-confirm-btn'
      },
      backdrop: `rgba(20, 18, 24, 0.8)`
    })
  }
}

const getUser = async () => {
  try {
    const response = await store.dispatch('auth/getUser', user.value.id)
    loading.value = false
  } catch (error) {
    loading.value = false
    console.error('Error:', error)
    await Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message,
      showConfirmButton: true,
      customClass: {
        popup: 'dark-swal',
        confirmButton: 'swal-confirm-btn'
      },
      backdrop: `rgba(20, 18, 24, 0.8)`
    })
  }
}

const contactUs = () => {
  emit('contact')
}
</script>

<style >
.dark-swal {
  background: rgb(17, 24, 39) !important;
  color: #ffffff !important;
  border: 1px solid #211f26 !important;
}

.swal-confirm-btn {
  background: rgb(79, 70, 229) !important;
  color: white !important;
}

.swal2-title {
  color: #ffffff !important;
}

.swal2-html-container {
  color: #9ca3af !important;
}
</style>