<script setup>
import { ref, computed, onMounted } from 'vue'
import { MoreVertical, Plus } from 'lucide-vue-next'
import SettingsModal from './SettingsModal.vue'
import Swal from 'sweetalert2'
import { useStore } from 'vuex'

const store = useStore()
const integrations = computed(() => store.getters['channels/channels'])

console.log(integrations)
// const integrations = ref([
//   // {
//   //   id: 1,
//   //   name: 'Nike Official',
//   //   icon: 'https://res.cloudinary.com/mioven/image/upload/v1732753747/facebook.png',
//   //   description: 'Real-time comments from Nike Official.',
//   //   status: true,
//   //   category: 'Social Media'
//   // },
//   // {
//   //   id: 2,
//   //   name: 'Nike Spain',
//   //   icon: 'https://res.cloudinary.com/mioven/image/upload/v1732753747/facebook.png',
//   //   description: 'Real-time comments from Nike Spain.',
//   //   status: false,
//   //   category: 'Social Media'
//   // },
//   // {
//   //   id: 3,
//   //   name: 'Instagram',
//   //   icon: 'https://res.cloudinary.com/mioven/image/upload/v1732753747/instagram.png',
//   //   description: 'Get real-time comments from your instagram pages.',
//   //   status: true,
//   //   category: 'Social Media'
//   // },
//   // {
//   //   id: 4,
//   //   name: 'Youtube',
//   //   icon: 'https://www.teamphenomenalhope.org/wp-content/uploads/2017/04/YouTube-icon-full_color-338x338.png',
//   //   description: 'Get real-time comments from your youtube channels',
//   //   status: true,
//   //   category: 'Social Media'
//   // },
//   // {
//   //   id: 5,
//   //   name: 'Slack',
//   //   icon: 'https://assets.mofoprod.net/network/images/slack.original.jpg',
//   //   description: 'Share your feedback automatically to Slack.',
//   //   status: true,
//   //   category: 'Collaboration'
//   // },
//   // {
//   //   id: 6,
//   //   name: 'Zendesk',
//   //   icon: 'https://cyclr.com/wp-content/uploads/2022/03/ext-638.png',
//   //   description: 'Bring support and sales conversations in.',
//   //   status: true,
//   //   category: 'Helpdesks'
//   // },
//   // {
//   //   id: 7,
//   //   name: 'Asana',
//   //   icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpqFhU4nnZmBiGv4GwtdP98-lq4RFf1nCsLBhK12thrUC18u-rjcYmvR5Nc9QZSuFDbXQ&usqp=CAU',
//   //   description:
//   //     'Create tasks from your feedback in Asana with a click of a button.',
//   //   status: true,
//   //   category: 'Collaboration'
//   // }
// ])
const formatNewIntegration = (connection) => {
  const maxId = Math.max(...integrations.value.map((i) => i.id), 0)
  return {
    id: maxId + 1,
    name: connection.name,
    icon:
      connection.platform === 'facebook'
        ? 'https://res.cloudinary.com/mioven/image/upload/v1732753747/facebook.png'
        : 'https://res.cloudinary.com/mioven/image/upload/v1732753747/instagram.png',
    description: `Real-time comments from ${connection.name}`,
    status: true,
    category: 'Social Media',
    // Core platform properties
    type: connection.platform,
    pageId: connection.pageId,
    accessToken: connection.accessToken,
    // Instagram specific
    username: connection.platform === 'instagram' ? connection.username : null
  }
}

const categories = ref([
  'All Channels',
  'Social Media',
  'Helpdesks',
  'Surveys',
  'Collaboration'
])

const selectedCategory = ref('All Channels')
const searchQuery = ref('')
const openDropdowns = ref(new Set())

const toggleDropdown = (integrationId) => {
  if (openDropdowns.value.has(integrationId)) {
    openDropdowns.value.delete(integrationId)
  } else {
    openDropdowns.value.clear() // Close any other open dropdowns
    openDropdowns.value.add(integrationId)
  }
}

const closeAllDropdowns = () => {
  openDropdowns.value.clear()
}

onMounted(() => {
  getChannels()

  document.addEventListener('click', (e) => {
    const dropdownButtons = document.querySelectorAll('.dropdown-button')
    const dropdownMenus = document.querySelectorAll('.dropdown-menu')

    if (
      ![...dropdownButtons, ...dropdownMenus].some((el) =>
        el?.contains(e.target)
      )
    ) {
      closeAllDropdowns()
    }
  })
})

const getChannels = async () => {
  try {
    const response = await store.dispatch('channels/getChannels')
    console.log(integrations)
  } catch (error) {
    console.error('Error:', error)
    await Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message,
      showConfirmButton: true,
      customClass: {
        popup: 'dark-swal',
        confirmButton: 'swal-confirm-btn'
      },
      backdrop: `rgba(20, 18, 24, 0.8)`
    })
  }
}

const filteredIntegrations = computed(() => {
  return integrations.value.filter((integration) => {
    console.log(integration)
    const matchesCategory =
      selectedCategory.value === 'All Channels' ||
      integration.category === selectedCategory.value
    const matchesSearch =
      searchQuery.value === '' ||
      integration.name.includes(searchQuery.value) ||
      integration.description.includes(searchQuery.value)
    return matchesCategory && matchesSearch
  })
})

const toggleStatus = (id) => {
  const integration = integrations.value.find((i) => i.id === id)
  if (integration) {
    integration.status = integration.status ? false : true
  }
}

const show = ref(false)
const selectedChannel = ref({})
const facebookLoading = ref(false)
const facebookError = ref(null)
const debugInfo = ref(null)

const openChannelSettings = (channel) => {
  selectedChannel.value = channel
  show.value = true
}

const closeModal = () => {
  selectedChannel.value = {}
  show.value = false
}
const initFB = () => {
  return new Promise((resolve) => {
    // If FB SDK is already loaded
    if (window.FB) {
      FB.getLoginStatus((response) => {
        resolve(response)
      }, true)
      return
    }

    // Initialize FB SDK
    window.fbAsyncInit = function () {
      FB.init({
        appId: '380346868438281',
        cookie: true,
        xfbml: true,
        version: 'v18.0'
      })

      // Check login status after init
      FB.getLoginStatus((response) => {
        resolve(response)
      }, true)
    }

    // Load FB SDK script
    const script = document.createElement('script')
    script.id = 'facebook-jssdk'
    script.src = 'https://connect.facebook.net/en_US/sdk.js'
    script.async = true
    script.defer = true

    const firstScript = document.getElementsByTagName('script')[0]
    firstScript.parentNode.insertBefore(script, firstScript)
  })
}

const authenticateWithPage = async () => {
  try {
    facebookLoading.value = true
    facebookError.value = null

    await initFB()

    const loginResponse = await new Promise((resolve) => {
      FB.login(resolve, {
        scope: [
          'pages_show_list',
          'instagram_basic',
          'instagram_manage_comments',
          'pages_read_engagement',
          'pages_manage_metadata',
          'pages_read_user_content',
          'pages_manage_posts',
          'pages_manage_engagement',
          'public_profile'
        ].join(','),
        return_scopes: true
      })
    })

    if (loginResponse.status !== 'connected') {
      throw new Error('Facebook authentication failed')
    }

    const pagesResponse = await new Promise((resolve) => {
      FB.api(
        '/me/accounts',
        {
          access_token: loginResponse.authResponse.accessToken,
          fields:
            'name,access_token,id,instagram_business_account{id,name,username}'
        },
        resolve
      )
    })

    if (pagesResponse.error) {
      throw new Error(`Facebook API Error: ${pagesResponse.error.message}`)
    }

    if (!pagesResponse.data?.length) {
      throw new Error(
        'No pages were connected. Please make sure to select and confirm access to your pages.'
      )
    }

    // Format connections
    const connections = pagesResponse.data.flatMap((page) => {
      const connections = [
        {
          pageId: page.id,
          name: page.name,
          accessToken: page.access_token,
          platform: 'facebook'
        }
      ]

      if (page.instagram_business_account) {
        connections.push({
          pageId: page.instagram_business_account.id,
          name: page.instagram_business_account.name,
          username: page.instagram_business_account.username,
          accessToken: page.access_token,
          platform: 'instagram'
        })
      }

      return connections
    })

    // Save to backend
    const response = await store.dispatch(
      'auth/saveSocialConnections',
      connections
    )
    if (response.error) {
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response.data.meta.message,
        showConfirmButton: true,
        customClass: {
          popup: 'dark-swal',
          confirmButton: 'swal-confirm-btn'
        },
        backdrop: `rgba(20, 18, 24, 0.8)`
      })
      return []
    }
    //if (response.data.meta.success) {
    // Add to local integrations
    connections.forEach((connection) => {
      integrations.value.push(formatNewIntegration(connection))
    })

    await Swal.fire({
      icon: 'success',
      title: 'Success',
      text: `Successfully connected ${connections.length} page(s)`,
      showConfirmButton: true,
      customClass: {
        popup: 'dark-swal',
        confirmButton: 'swal-confirm-btn'
      },
      backdrop: `rgba(20, 18, 24, 0.8)`
    })

    return connections
    // }
    //  else {
    console.log(error)
    facebookError.value = error.message
    await Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'We could not connect your channel. This may be due to you exiting the auth process',
      showConfirmButton: true,
      customClass: {
        popup: 'dark-swal',
        confirmButton: 'swal-confirm-btn'
      },
      backdrop: `rgba(20, 18, 24, 0.8)`
    })
    return []
    //  }
  } catch (error) {
    console.log(error)
    facebookError.value = error.message
    await Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.response
        ? error.response.data.meta.message
        : 'We could not connect your channel. This might be because you or facebook aborted the connection process. Please try again later. If this issue persists, contact support.',
      showConfirmButton: true,
      customClass: {
        popup: 'dark-swal',
        confirmButton: 'swal-confirm-btn'
      },
      backdrop: `rgba(20, 18, 24, 0.8)`
    })
    return []
  } finally {
    facebookLoading.value = false
  }
}

const addNewIntegration = () => {
  authenticateWithPage()
}
</script>

<template>
  <div class="">
    <SettingsModal
      :show="show"
      :channel="selectedChannel"
      @close="closeModal()"
    />
    <!-- Header with Add Button -->
    <div class="flex justify-between items-center mb-8">
      <h2 class="text-xl font-semibold text-white">Channels</h2>
      <button
        @click="addNewIntegration"
        class="flex items-center gap-2 px-4 py-2 bg-indigo-600 hover:bg-indigo-500 text-white rounded-lg transition-colors duration-200 font-medium"
      >
        <Plus class="w-5 h-5" />
        <span>Add Channel</span>
      </button>
    </div>

    <!-- Search -->
    <div class="relative mb-8">
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Search channels..."
        class="w-full px-4 py-3 bg-gray-800 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600 border border-gray-700 hover:border-gray-600 transition-colors"
      />
    </div>
    <!-- Search -->
    <!-- <div class="relative mb-8">
        <input
          v-model="searchQuery"
          type="text"
          placeholder="Search integrations..."
          class="w-full px-4 py-3 bg-gray-800 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600 border border-gray-700 hover:border-gray-600 transition-colors"
        />
      </div> -->

    <!-- Integrations List -->
    <TransitionGroup name="list" tag="div" class="grid gap-4">
      <div
        v-for="integration in filteredIntegrations"
        :key="integration.id"
        class="group flex items-center justify-between p-4 sm:p-6 bg-gray-900 rounded-lg space-y-4 sm:space-y-0 transition-colors duration-200"
      >
        <!-- Left side: Icon and Content -->
        <div class="flex items-center space-x-4">
          <div
            class="w-12 h-12 flex items-center justify-center rounded-lg bg-gray-700/50 group-hover:bg-gray-700/70 transition-colors duration-200"
          >
            <img
              :src="integration.icon"
              :alt="`${integration.name} icon`"
              class="w-10 h-10 object-contain"
              loading="lazy"
            />
          </div>
          <div class="min-w-0 flex-1">
            <h3
              class="text-lg font-medium text-white truncate group-hover:text-indigo-300 transition-colors duration-200"
            >
              {{ integration.name }}
            </h3>
            <p
              class="text-gray-400 text-sm group-hover:text-gray-300 transition-colors duration-200"
            >
              {{ integration.description }}
            </p>
          </div>
        </div>

        <!-- Right side: Toggle and Menu -->
        <div class="flex items-center space-x-4">
          <!-- Toggle Switch -->
          <label class="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              :checked="integration.status"
              @change="toggleStatus(integration.id)"
              class="sr-only peer"
            />
            <div
              class="w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-indigo-600"
            ></div>
          </label>

          <!-- Menu Button -->
          <div class="relative">
            <button
              @click.stop="toggleDropdown(integration.id)"
              class="p-2 hover:bg-gray-800 rounded-lg transition-colors dropdown-button"
            >
              <MoreVertical class="h-5 w-5 text-gray-400 hover:text-white" />
            </button>

            <!-- Dropdown Menu -->
            <div
              v-show="openDropdowns.has(integration.id)"
              class="absolute right-0 mt-1 w-48 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5 dropdown-menu z-10"
            >
              <!-- dropdown content -->

              <!-- Dropdown Menu -->
              <div
                v-show="openDropdowns.has(integration.id)"
                class="absolute right-0 mt-1 w-48 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5 dropdown-menu z-10"
              >
                <div class="py-1" role="menu" aria-orientation="vertical">
                  <!-- Settings -->
                  <button
                    @click="openChannelSettings(integration)"
                    class="flex items-center w-full px-4 py-2 text-sm text-gray-300 hover:bg-gray-700"
                    role="menuitem"
                  >
                    <svg
                      class="mr-3 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    Settings
                  </button>

                  <div class="border-t border-gray-700"></div>

                  <!-- Remove -->
                  <button
                    @click="confirmRemoveChannel(integration)"
                    class="flex items-center w-full px-4 py-2 text-sm text-red-400 hover:bg-gray-700"
                    role="menuitem"
                  >
                    <svg
                      class="mr-3 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                    Remove Channel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TransitionGroup>

    <!-- Empty State -->

    <div
      v-if="filteredIntegrations.length === 0"
      class="flex flex-col items-center justify-center py-8 px-4"
    >
      <svg
        width="154"
        height="154"
        viewBox="0 0 154 154"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_5449_72)">
          <path
            d="M103.453 0.199707C75.6909 0.199707 53.1047 22.7859 53.1047 50.5478C53.1047 63.6213 58.1146 75.5454 66.3135 84.5052L57.0198 93.7988L56.9103 93.6893C55.5771 92.3561 53.804 91.6214 51.9182 91.6214C50.0323 91.6214 48.2596 92.3558 46.926 93.6893L2.96875 137.647C-0.721355 141.337 -0.721355 147.342 2.96875 151.032C4.81381 152.877 7.23758 153.8 9.66135 153.8C12.0851 153.8 14.5089 152.877 16.3539 151.032L60.3112 107.075C61.6448 105.741 62.3792 103.969 62.3792 102.083C62.3792 100.197 61.6448 98.4241 60.3112 97.0905L60.2017 96.981L69.4954 87.6874C78.4552 95.8863 90.3796 100.896 103.453 100.896C131.215 100.896 153.801 78.31 153.801 50.5478C153.801 22.7859 131.215 0.199707 103.453 0.199707ZM13.1721 147.85C11.2364 149.786 8.0866 149.786 6.15095 147.85C4.21529 145.914 4.21529 142.764 6.15095 140.829L43.0739 103.906L50.095 110.927L13.1721 147.85ZM57.1293 103.893L53.2769 107.745L46.2558 100.724L50.1082 96.8715C50.5916 96.3882 51.2345 96.1218 51.9182 96.1218C52.6019 96.1218 53.2448 96.3882 53.7281 96.8715L57.129 100.273C57.6127 100.756 57.8788 101.399 57.8788 102.083C57.8791 102.766 57.613 103.409 57.1293 103.893ZM103.453 96.3963C78.172 96.3963 57.6049 75.8289 57.6049 50.5481C57.6049 25.2672 78.1723 4.69984 103.453 4.69984C128.733 4.69984 149.301 25.2672 149.301 50.5478C149.301 75.8286 128.734 96.3963 103.453 96.3963Z"
            fill="#6366F1"
          />
          <path
            d="M132.253 21.7484C117.771 7.26669 94.6617 5.82635 78.4997 18.3973C77.5186 19.1602 77.3419 20.5739 78.1052 21.5546C78.8678 22.5356 80.2814 22.7123 81.2625 21.9491C95.6377 10.7681 116.191 12.05 129.071 24.9303C141.951 37.8109 143.233 58.3639 132.052 72.7385C131.289 73.7195 131.466 75.1332 132.447 75.8961C132.857 76.2153 133.344 76.3701 133.826 76.3701C134.497 76.3701 135.161 76.0716 135.604 75.5013C148.175 59.3392 146.735 36.2304 132.253 21.7484Z"
            fill="#6366F1"
          />
          <path
            d="M125.644 79.1459C119.092 84.2424 111.258 86.7496 103.45 86.7493C94.1282 86.749 84.845 83.1744 77.8359 76.165C64.9557 63.2847 63.674 42.7314 74.8547 28.3568C75.6177 27.3757 75.441 25.9621 74.4602 25.1992C73.4789 24.4363 72.0656 24.613 71.3029 25.5937C58.7314 41.7564 60.172 64.8651 74.654 79.3469C82.5356 87.2287 92.9705 91.2473 103.452 91.247C112.23 91.2467 121.041 88.4275 128.407 82.698C129.388 81.935 129.565 80.5214 128.802 79.5404C128.039 78.5599 126.625 78.3829 125.644 79.1459Z"
            fill="#6366F1"
          />
          <path
            d="M85.0262 49.7339C85.4654 50.1734 86.0415 50.393 86.6172 50.393C87.1929 50.393 87.7689 50.1734 88.2081 49.7339L91.4269 46.5151L94.6457 49.7339C95.0849 50.1734 95.6609 50.393 96.2367 50.393C96.8124 50.393 97.3884 50.1734 97.8276 49.7339C98.7063 48.8551 98.7063 47.4307 97.8276 46.552L94.6088 43.3332L97.8276 40.1144C98.7063 39.2356 98.7063 37.8112 97.8276 36.9325C96.9489 36.0538 95.5241 36.0538 94.6457 36.9325L91.4269 40.1513L88.2081 36.9325C87.3294 36.0538 85.9047 36.0538 85.0262 36.9325C84.1475 37.8112 84.1475 39.2359 85.0262 40.1144L88.245 43.3332L85.0262 46.552C84.1475 47.4307 84.1475 48.8554 85.0262 49.7339Z"
            fill="#6366F1"
          />
          <path
            d="M109.077 49.7339C109.516 50.1734 110.092 50.393 110.668 50.393C111.244 50.393 111.82 50.1734 112.259 49.7339L115.478 46.5151L118.697 49.7339C119.136 50.1734 119.712 50.393 120.288 50.393C120.863 50.393 121.439 50.1734 121.879 49.7339C122.757 48.8551 122.757 47.4304 121.879 46.552L118.66 43.3332L121.879 40.1144C122.757 39.2356 122.757 37.8112 121.879 36.9325C121 36.0538 119.575 36.0538 118.697 36.9325L115.478 40.1513L112.259 36.9325C111.38 36.0538 109.956 36.0538 109.077 36.9325C108.199 37.8112 108.199 39.2359 109.077 40.1144L112.296 43.3332L109.077 46.552C108.198 47.4307 108.198 48.8554 109.077 49.7339Z"
            fill="#6366F1"
          />
          <path
            d="M89.8353 65.7925C88.9575 66.6721 88.9587 68.0969 89.838 68.9747C90.2772 69.413 90.8524 69.6323 91.4278 69.6323C92.0041 69.6323 92.5807 69.4121 93.0202 68.972C95.6525 66.3349 99.4548 64.8226 103.452 64.8226C107.449 64.8226 111.252 66.3349 113.884 68.972C114.762 69.8516 116.187 69.8528 117.066 68.9747C117.946 68.0969 117.947 66.6721 117.069 65.7928C113.599 62.3163 108.635 60.3228 103.452 60.3228C98.2689 60.3222 93.3055 62.316 89.8353 65.7925Z"
            fill="#6366F1"
          />
        </g>
        <defs>
          <clipPath id="clip0_5449_72">
            <rect
              width="153.6"
              height="153.6"
              fill="white"
              transform="translate(0.199219 0.200195)"
            />
          </clipPath>
        </defs>
      </svg>

      <!-- <svg
          class="w-16 h-16 mb-4 text-gray-600"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="12" cy="8" r="7" />
          <path d="M15.5 8a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
          <path
            d="M12 2v1M12 13v1M2 8h1M21 8h1M4.22 4.22l.707.707M18.364 4.222l-.707.707"
          />
          <path
            d="M17.7 13.4A10 10 0 0 1 22 19.8M6.3 13.4A10 10 0 0 0 2 19.8M12 15a10 10 0 0 0-10 10M12 15a10 10 0 0 1 10 10"
          />
        </svg> -->
      <h3 class="text-lg font-medium text-gray-300 mb-1 mt-4">
        No channels found
      </h3>
      <p class="text-gray-500">Try adjusting your search or filters</p>
    </div>
  </div>
</template>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-move {
  transition: transform 0.3s ease;
}
</style>