import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

// Load the relative time plugin
dayjs.extend(relativeTime)

// Optionally set locale (default is 'en')
import 'dayjs/locale/en'
dayjs.locale('en')

export default dayjs
