<template>
  <div v-if="loading"><div class="spinner"></div></div>
  <div class="" v-if="!loading">
    <div class="flex justify-between items-center mb-8">
      <h2 class="text-xl font-semibold text-white">Dashboard</h2>
      <!-- <button
          @click="manageBilling()"
          class="flex items-center gap-2 px-4 py-2 bg-indigo-600 hover:bg-indigo-500 text-white rounded-lg transition-colors duration-200 font-medium"
        >
          <WalletMinimal class="w-5 h-5" />
          <span>Manage Billing</span>
        </button> -->
    </div>

    <!-- backend search bar -->

    <!-- Search Bar -->
    <div class="mb-6">
      <form @submit.prevent="handleSearch" class="flex gap-2">
        <div class="relative flex-1">
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Search comments..."
            class="w-full px-4 py-2 bg-gray-900 border border-gray-700 rounded-lg text-gray-300 focus:outline-none focus:border-indigo-500 transition-colors duration-200"
          />
          <button
            type="button"
            @click="clearSearch"
            v-if="searchQuery"
            class="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300"
          >
            <svg
              class="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <button
          type="submit"
          class="px-4 py-2 bg-indigo-600 hover:bg-indigo-500 text-white rounded-lg transition-colors duration-200 flex items-center gap-2"
        >
          <svg
            class="w-5 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
          Search
        </button>
      </form>
    </div>

    <!-- Filter Buttons -->
    <CommentFilters />

    <!-- Comments Grid -->
    <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
      <template v-for="comment in filteredComments" :key="comment.id">
        <div
          class="flex h-auto min-h-[12rem] flex-col rounded-lg bg-gray-900 p-4"
        >
          <!-- Comment Header -->
          <div class="flex items-start justify-between mb-3">
            <div class="flex gap-3">
              <!-- Platform Avatar -->
              <div
                :class="[
                  'w-10 h-10 rounded-full flex items-center justify-center',
                  {
                    'bg-blue-500/10': comment.platform === 'facebook',
                    'bg-pink-500/10': comment.platform === 'instagram',
                    'bg-red-500/10': comment.platform === 'youtube'
                  }
                ]"
              >
                <!-- Platform Icons -->
                <img
                  :src="platformIcons[comment.platform]"
                  :alt="comment.platform"
                  class="w-5 h-5"
                />
              </div>

              <div class="flex flex-col">
                <span class="font-medium text-white">{{
                  comment.author.name
                }}</span>
                <div class="text-xs text-gray-400 flex items-center gap-1">
                  <svg
                    class="w-3 h-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  {{ formatCommentTime(comment.commentTime) }}
                </div>
              </div>
            </div>

            <!-- menu -->
            <div class="relative">
              <button
                @click.stop="toggleDropdown(comment.id)"
                class="p-1 hover:bg-gray-800 rounded-full dropdown-button"
              >
                <svg
                  class="w-5 h-5 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                  />
                </svg>
              </button>

              <!-- Dropdown Menu -->
              <div
                v-show="openDropdowns.has(comment.id)"
                class="absolute right-0 mt-1 w-48 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5 dropdown-menu z-10"
              >
                <div class="py-1" role="menu" aria-orientation="vertical">
                  <button
                    @click="() => {}"
                    class="flex items-center w-full px-4 py-2 text-sm text-gray-300 hover:bg-gray-700"
                    role="menuitem"
                  >
                    <svg
                      class="mr-3 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </svg>
                    View Details
                  </button>

                  <button
                    @click="() => {}"
                    class="flex items-center w-full px-4 py-2 text-sm text-gray-300 hover:bg-gray-700"
                    role="menuitem"
                  >
                    <svg
                      class="mr-3 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    Mark as Resolved
                  </button>

                  <button
                    @click="() => {}"
                    class="flex items-center w-full px-4 py-2 text-sm text-gray-300 hover:bg-gray-700"
                    role="menuitem"
                  >
                    <svg
                      class="mr-3 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9"
                      />
                    </svg>
                    Flag as Important
                  </button>

                  <div class="border-t border-gray-700"></div>

                  <button
                    @click="deleteComment(comment)"
                    class="flex items-center w-full px-4 py-2 text-sm text-red-400 hover:bg-gray-700"
                    role="menuitem"
                  >
                    <svg
                      class="mr-3 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Comment Content -->
          <div class="flex-1 text-gray-300 mb-4 break-words">
            {{
              comment.translation?.length > 1000
                ? comment.translation?.slice(0, 1000) + '...'
                : comment.translation
            }}
          </div>

          <!-- Metadata/Badges -->
          <div class="flex items-center gap-2 ]">
            <!-- Sentiment Badge -->
            <div
              :class="[
                'flex items-center gap-1.5 px-2.5 py-1 rounded-full text-sm',
                comment.sentiment === 'positive'
                  ? 'bg-green-500/10 text-green-400'
                  : comment.sentiment === 'negative'
                  ? 'bg-red-500/10 text-red-400'
                  : 'bg-gray-300/10 text-grey-300'
              ]"
            >
              <svg
                v-if="comment.sentiment == 'positive'"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_5447_50)">
                  <path
                    d="M6.9993 13.7198C3.29392 13.7198 0.279297 10.7052 0.279297 6.99979C0.279297 3.29441 3.29392 0.279785 6.9993 0.279785C10.7047 0.279785 13.7193 3.29441 13.7193 6.99979C13.7193 10.7052 10.7047 13.7198 6.9993 13.7198ZM6.9993 1.11979C3.75704 1.11979 1.1193 3.75753 1.1193 6.99979C1.1193 10.242 3.75704 12.8798 6.9993 12.8798C10.2416 12.8798 12.8793 10.242 12.8793 6.99979C12.8793 3.75753 10.2416 1.11979 6.9993 1.11979ZM10.7351 8.44767C10.8382 8.24117 10.7548 7.99154 10.5493 7.88682C10.3433 7.7828 10.0913 7.86471 9.98508 8.06939C9.95428 8.12847 9.20822 9.51979 6.9993 9.51979C4.7957 9.51979 4.04796 8.13533 4.0138 8.06995C3.90978 7.86359 3.65792 7.78001 3.45142 7.88417C3.24394 7.98791 3.1598 8.24019 3.26354 8.44767C3.30274 8.52579 4.25124 10.3598 6.9993 10.3598C9.74736 10.3598 10.6959 8.52579 10.7351 8.44767ZM9.0993 6.15979C8.63604 6.15979 8.2593 5.78305 8.2593 5.31979C8.2593 4.85653 8.63604 4.47979 9.0993 4.47979C9.56256 4.47979 9.9393 4.85653 9.9393 5.31979C9.9393 5.78305 9.56256 6.15979 9.0993 6.15979ZM4.8993 6.15979C4.43604 6.15979 4.0593 5.78305 4.0593 5.31979C4.0593 4.85653 4.43604 4.47979 4.8993 4.47979C5.36256 4.47979 5.7393 4.85653 5.7393 5.31979C5.7393 5.78305 5.36256 6.15979 4.8993 6.15979Z"
                    fill="currentColor"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5447_50">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <svg
                v-if="comment.sentiment == 'negative'"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_5447_29)">
                  <path
                    d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM7 13.2432C5.28512 13.2432 3.72968 12.5481 2.6002 11.4251C2.14917 10.9766 1.76629 10.4597 1.46818 9.8917C1.01416 9.02667 0.756767 8.04287 0.756767 7C0.756767 3.55747 3.55747 0.756767 7 0.756767C8.63287 0.756767 10.121 1.38715 11.2347 2.41702C11.8121 2.95092 12.2889 3.59209 12.6328 4.30875C13.0239 5.12412 13.2432 6.03692 13.2432 7C13.2432 10.4425 10.4425 13.2432 7 13.2432Z"
                    fill="currentColor"
                  />
                  <path
                    d="M7.73948 8.8734C8.56974 9.04703 9.31554 9.51382 9.8395 10.1878L10.4369 9.7233C9.80304 8.90788 8.90002 8.34296 7.89438 8.13262C6.27702 7.79463 4.57661 8.41887 3.5625 9.7233L4.16001 10.1878C4.99795 9.10989 6.40302 8.59387 7.73948 8.8734Z"
                    fill="currentColor"
                  />
                  <path
                    d="M4.66692 6.19615C5.08487 6.19615 5.42369 5.85734 5.42369 5.43938C5.42369 5.02143 5.08487 4.68262 4.66692 4.68262C4.24897 4.68262 3.91016 5.02143 3.91016 5.43938C3.91016 5.85734 4.24897 6.19615 4.66692 6.19615Z"
                    fill="currentColor"
                  />
                  <path
                    d="M9.34856 6.19615C9.76651 6.19615 10.1053 5.85734 10.1053 5.43938C10.1053 5.02143 9.76651 4.68262 9.34856 4.68262C8.93061 4.68262 8.5918 5.02143 8.5918 5.43938C8.5918 5.85734 8.93061 6.19615 9.34856 6.19615Z"
                    fill="currentColor"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5447_29">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <svg
                v-if="comment.sentiment == 'neutral'"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_5447_2)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.728516 7.00008C0.728516 3.5368 3.53606 0.729248 6.99935 0.729248C10.4627 0.729248 13.2702 3.5368 13.2702 7.00008C13.2702 10.4634 10.4627 13.2709 6.99935 13.2709C3.53606 13.2709 0.728516 10.4634 0.728516 7.00008ZM6.99935 1.60425C4.01931 1.60425 1.60352 4.02004 1.60352 7.00008C1.60352 9.9801 4.01931 12.3959 6.99935 12.3959C9.97937 12.3959 12.3952 9.9801 12.3952 7.00008C12.3952 4.02004 9.97937 1.60425 6.99935 1.60425Z"
                    fill="currentColor"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.04102 4.8125C9.28263 4.8125 9.47852 5.00838 9.47852 5.25V5.54167C9.47852 5.78329 9.28263 5.97917 9.04102 5.97917C8.7994 5.97917 8.60352 5.78329 8.60352 5.54167V5.25C8.60352 5.00838 8.7994 4.8125 9.04102 4.8125Z"
                    fill="currentColor"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.95703 4.8125C5.19865 4.8125 5.39453 5.00838 5.39453 5.25V5.54167C5.39453 5.78329 5.19865 5.97917 4.95703 5.97917C4.71541 5.97917 4.51953 5.78329 4.51953 5.54167V5.25C4.51953 5.00838 4.71541 4.8125 4.95703 4.8125Z"
                    fill="currentColor"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.51953 9.04175C4.51953 8.80013 4.71541 8.60425 4.95703 8.60425H9.04036C9.28198 8.60425 9.47786 8.80013 9.47786 9.04175C9.47786 9.28336 9.28198 9.47925 9.04036 9.47925H4.95703C4.71541 9.47925 4.51953 9.28336 4.51953 9.04175Z"
                    fill="currentColor"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5447_2">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              {{ comment.sentiment }}
            </div>

            <!-- Engagement Stats -->
            <div
              v-for="tag in comment.systemTags"
              :key="tag"
              class="flex items-center gap-1.5 px-2.5 py-1 rounded-full bg-blue-500/10 text-blue-400 text-sm"
            >
              <svg
                class="w-3.5 h-3.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                />
              </svg>
              {{ capitaliseFirstLetter(tag) }}
            </div>

            <!-- intent -->
            <div
              class="flex items-center gap-1.5 px-2.5 py-1 rounded-full bg-purple-500/10 text-purple-400 text-sm"
            >
              <svg
                class="w-3.5 h-3.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                />
              </svg>
              {{ capitaliseFirstLetter(comment.intent) }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import dayjs from '@/utils/dayjs'
import Swal from 'sweetalert2'
import { ref, computed, onMounted } from 'vue'
import CommentFilters from './CommentFilters.vue'

import { useStore } from 'vuex'
const store = useStore()
const loading = ref(true)

const getComments = async (search = '') => {
  let query = 'limit=100'
  if (search) {
    query += `&query=${search}`
  }

  try {
    const response = await store.dispatch('comments/getComments', query)
    loading.value = false
    if (search && response.data.meta.ai) {
      await Swal.fire({
        icon: 'info',
        title: 'AI Summary',
        html: `<div class="text-left whitespace-pre-line">${response.data.meta.ai}</div>`,
        width: '60vw',
        showConfirmButton: true,
        customClass: {
          popup: 'dark-swal',
          confirmButton: 'swal-confirm-btn'
        },
        backdrop: `rgba(20, 18, 24, 0.8)`
      })
    }

    return response
  } catch (error) {
    loading.value = false
    console.error('Error:', error)
    await Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message,
      showConfirmButton: true,
      customClass: {
        popup: 'dark-swal',
        confirmButton: 'swal-confirm-btn'
      },
      backdrop: `rgba(20, 18, 24, 0.8)`
    })
  }
}

// Delete comment method
const deleteComment = async (comment) => {
  try {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this action!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      customClass: {
        popup: 'dark-swal',
        confirmButton: 'swal-confirm-btn',
        cancelButton: 'swal-cancel-btn'
      },
      backdrop: `rgba(20, 18, 24, 0.8)`
    })

    // If user confirms deletion
    if (result.isConfirmed) {
      //  loading.value = true

      // Dispatch delete action to Vuex store
      await store.dispatch('comments/deleteComment', comment.id)

      // Close the dropdown for this comment
      openDropdowns.value.delete(comment.id)

      // Update filtered comments
      const remainingComments = comments.value.filter(
        (c) => c.id !== comment.id
      )
      store.commit('comments/SET_FILTERED_COMMENTS', remainingComments)

      // Show success message
      await Swal.fire({
        icon: 'success',
        title: 'Deleted!',
        text: 'Comment has been deleted.',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'dark-swal'
        },
        backdrop: `rgba(20, 18, 24, 0.8)`
      })
    }
  } catch (error) {
    console.error('Error deleting comment:', error)
    await Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to delete comment. Please try again.',
      showConfirmButton: true,
      customClass: {
        popup: 'dark-swal',
        confirmButton: 'swal-confirm-btn'
      },
      backdrop: `rgba(20, 18, 24, 0.8)`
    })
  } finally {
  }
}

const searchQuery = ref('')

const handleSearch = async () => {
  loading.value = true
  await getComments(searchQuery.value)
}

const clearSearch = async () => {
  searchQuery.value = ''
  loading.value = true
  await getComments()
}

const comments = computed(() => store.getters['comments/filteredComments'])
const platformIcons = computed(() => store.getters['comments/platformIcons'])

const formatCommentTime = (commentTime) => {
  return dayjs(commentTime).from(dayjs())
}

const openDropdowns = ref(new Set())

const toggleDropdown = (commentId) => {
  if (openDropdowns.value.has(commentId)) {
    openDropdowns.value.delete(commentId)
  } else {
    openDropdowns.value.clear() // Close any other open dropdowns
    openDropdowns.value.add(commentId)
  }
}

// Close dropdown when clicking outside
const closeAllDropdowns = () => {
  openDropdowns.value.clear()
}

onMounted(() => {
  getComments()

  document.addEventListener('click', (e) => {
    const dropdownButtons = document.querySelectorAll('.dropdown-button')
    const dropdownMenus = document.querySelectorAll('.dropdown-menu')

    if (
      ![...dropdownButtons, ...dropdownMenus].some((el) =>
        el?.contains(e.target)
      )
    ) {
      closeAllDropdowns()
    }
  })
})

const selectedFilter = ref('all')

const setFilter = (filter) => {
  selectedFilter.value = filter
}

const filteredComments = computed(() => {
  if (selectedFilter.value === 'all') return comments.value
  return comments.value.filter((comment) => {
    if (
      selectedFilter.value === 'positive' ||
      selectedFilter.value === 'negative'
    ) {
      return comment.sentiment === selectedFilter.value
    }
    return comment.platform === selectedFilter.value
  })
})

const capitaliseFirstLetter = (str) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}
</script>

<style scoped>
</style>