<template>
  <div v-if="show" class="fixed mt-12 inset-0 z-50 overflow-y-auto">
    <!-- Modal backdrop -->
    <div
      class="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
      @click="closeModal"
    ></div>

    <!-- Modal content -->
    <div class="flex min-h-screen items-center justify-center p-4">
      <div
        class="relative w-full max-w-lg rounded-lg bg-gray-900 p-6 shadow-xl"
      >
        <!-- Close button -->
        <button
          @click="closeModal"
          class="absolute right-4 top-4 text-gray-400 hover:text-white"
        >
          <svg
            class="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <!-- Header -->
        <div class="flex items-center space-x-4">
          <div
            class="w-12 h-12 flex items-center justify-center rounded-lg bg-gray-800"
          >
            <img
              :src="channel?.icon"
              :alt="channel?.name"
              class="w-8 h-8 object-contain"
            />
          </div>
          <div>
            <h3 class="text-xl font-medium text-white">
              {{ channel?.name }} Settings
            </h3>
            <p class="text-sm text-gray-400">
              Manage your channel settings and preferences
            </p>
          </div>
        </div>

        <!-- Settings Form -->
        <form @submit.prevent="saveSettings" class="mt-6 space-y-6">
          <!-- General Settings -->
          <div class="space-y-4">
            <h4
              class="text-sm font-medium text-gray-300 uppercase tracking-wider"
            >
              General Settings
            </h4>

            <div>
              <label class="block text-sm font-medium text-gray-300"
                >Display Name</label
              >
              <input
                v-model="settings.displayName"
                type="text"
                class="mt-2 p-2 block w-full rounded-md border-gray-700 bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <!-- <div>
              <label class="block text-sm font-medium text-gray-300"
                >Webhook URL</label
              >
              <input
                v-model="settings.webhookUrl"
                type="url"
                class="mt-1 p-1.5 block w-full rounded-md border-gray-700 bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div> -->
          </div>

          <!-- Notification Settings -->
          <div class="space-y-4">
            <h4
              class="text-sm font-medium text-gray-300 uppercase tracking-wider"
            >
              Notifications
            </h4>

            <div class="flex items-center justify-between">
              <div>
                <label class="text-sm font-medium text-gray-300"
                  >Zendesk Notifications</label
                >
                <p class="text-xs text-gray-500">
                  Receive tickets in your helpdesk for new comments
                </p>
              </div>
              <label class="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  v-model="settings.emailNotifications"
                  class="sr-only peer"
                />
                <div
                  class="w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-indigo-600"
                ></div>
              </label>
            </div>

            <div class="flex items-center justify-between">
              <div>
                <label class="text-sm font-medium text-gray-300"
                  >Slack Notifications</label
                >
                <p class="text-xs text-gray-500">
                  Send notifications to Slack channel
                </p>
              </div>
              <label class="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  v-model="settings.slackNotifications"
                  class="sr-only peer"
                />
                <div
                  class="w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-indigo-600"
                ></div>
              </label>
            </div>
          </div>

          <!-- Advanced Settings -->
          <div class="space-y-4">
            <!-- <h4
              class="text-sm font-medium text-gray-300 uppercase tracking-wider"
            >
              Advanced
            </h4> -->

            <!-- <div>
              <label class="block text-sm font-medium text-gray-300"
                >Auto-Response Template</label
              >
              <textarea
                v-model="settings.autoResponseTemplate"
                rows="3"
                class="mt-1 p-1.5 block w-full rounded-md border-gray-700 bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                placeholder="Enter your auto-response template..."
              ></textarea>
            </div> -->
          </div>

          <!-- Danger Zone -->
          <div class="space-y-4 pt-6 border-t border-gray-800">
            <h4
              class="text-sm font-medium text-red-400 uppercase tracking-wider"
            >
              Danger Zone
            </h4>

            <div class="flex items-center justify-between">
              <div>
                <label class="text-sm font-medium text-gray-300"
                  >Connection Status</label
                >
                <p class="text-xs text-gray-500">
                  Disabling the channel will stop comments from coming through.
                </p>
              </div>
              <label class="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  v-model="settings.status"
                  class="sr-only peer"
                />
                <div
                  class="w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-indigo-600"
                ></div>
              </label>
            </div>
          </div>

          <!-- Save Button -->
          <div class="pt-6 border-t border-gray-800">
            <button
              type="submit"
              class="w-full rounded-md bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch } from 'vue'
import Swal from 'sweetalert2'

const props = defineProps({
  show: {
    type: Boolean,
    required: true
  },
  channel: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['close', 'save', 'remove'])

const settings = reactive({
  displayName: props.channel.name,
  webhookUrl: '',
  emailNotifications: false,
  slackNotifications: false,
  autoResponseTemplate: '',
  status: props.channel.status
})

watch(
  () => props.channel,
  (newChannel) => {
    if (newChannel) {
      settings.displayName = newChannel.name || ''
      settings.webhookUrl = newChannel.webhookUrl || ''
      settings.emailNotifications = newChannel.emailNotifications || false
      settings.slackNotifications = newChannel.slackNotifications || false
      settings.autoResponseTemplate = newChannel.autoResponseTemplate || ''
      settings.status = props.channel.status
    }
  },
  { immediate: true }
)

const closeModal = () => {
  emit('close')
}

const saveSettings = async () => {
  try {
    // Implement your save logic here
    emit('save', settings)
    closeModal()
  } catch (error) {
    await Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message,
      showConfirmButton: true,
      customClass: {
        popup: 'dark-swal',
        confirmButton: 'swal-confirm-btn'
      },
      backdrop: `rgba(20, 18, 24, 0.8)`
    })
  }
}

const confirmRemoveChannel = async () => {
  const result = await Swal.fire({
    title: 'Are you sure?',
    text: 'This action cannot be undone. All data associated with this channel will be permanently removed.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, remove it',
    cancelButtonText: 'Cancel',
    customClass: {
      popup: 'dark-swal',
      confirmButton: 'swal-confirm-btn'
    },
    backdrop: `rgba(20, 18, 24, 0.8)`
  })

  if (result.isConfirmed) {
    emit('remove', props.channel.id)
    closeModal()
  }
}
</script>