<template>
  <div class="min-h-screen bg-gray-950 py-12 px-4">
    <div class="mx-auto max-w-7xl">
      <!-- Header Section -->
      <div class="text-center mb-16">
        <h2 class="text-3xl font-bold text-white sm:text-4xl">
          Choose the perfect plan for your needs
        </h2>
        <p class="mt-4 text-lg text-gray-400">
          Get started with Zadabra today. No credit card required.
        </p>
      </div>

      <!-- Pricing Cards Grid -->
      <div class="grid gap-8 lg:grid-cols-3">
        <template v-for="plan in plans" :key="plan.tier">
          <div
            class="relative rounded-xl bg-gray-900 p-8 shadow-lg"
            :class="[
              plan.isPopular
                ? 'border-2 border-indigo-500'
                : 'border border-gray-800'
            ]"
          >
            <!-- Popular Badge -->
            <div v-if="plan.isPopular" class="absolute -top-4 right-8">
              <span
                class="inline-block rounded-full bg-indigo-500 px-4 py-1 text-sm font-medium text-white"
              >
                Most Popular
              </span>
            </div>

            <div class="space-y-6">
              <!-- Header -->
              <div>
                <h3 class="text-2xl font-bold text-white">{{ plan.tier }}</h3>
                <p class="mt-2 text-gray-400">{{ plan.description }}</p>
              </div>

              <!-- Pricing -->
              <div class="flex items-baseline">
                <span class="text-5xl font-bold text-white"
                  >£{{ plan.price }}</span
                >
                <span class="ml-2 text-gray-400">per {{ plan.period }}</span>
              </div>

              <!-- Features -->
              <ul class="space-y-4">
                <li
                  v-for="(feature, index) in plan.features"
                  :key="index"
                  class="flex items-center"
                >
                  <svg
                    class="h-5 w-5 text-indigo-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span class="ml-3 text-gray-300">{{ feature }}</span>
                </li>
              </ul>

              <!-- CTA Button -->
              <button
                @click="selectPlan(plan)"
                class="w-full rounded-lg px-6 py-3 text-center font-medium transition-all"
                :class="[
                  plan.isPopular
                    ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                    : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                ]"
              >
                Start Free Trial
              </button>
            </div>
          </div>
        </template>
      </div>

      <!-- Footer Section -->
      <div class="mt-12 text-center text-gray-400">
        <p>All plans include 14-day free trial. Cancel anytime.</p>
        <p class="mt-2">
          Need a custom plan?
          <span
            class="text-indigo-400 hover:text-indigo-300 cursor-pointer"
            @click="contactUs"
          >
            Contact us
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()
const showModal = computed(() => store.getters['auth/showLoginModal'])
const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])

const plans = ref([
  {
    tier: 'Essential',
    price: '29',
    period: 'month',
    description: 'For individuals getting started with feedback analysis',
    features: [
      'Social Integrations',
      'Manual Sources',
      'Sentiment Analysis',
      'Smart Tags',
      '500 Comments'
    ]
  },
  {
    tier: 'Business',
    price: '99',
    period: 'month',
    description: 'For teams ready to scale their feedback insights',
    features: [
      'Social Integrations',
      'Helpdesk Integrations',
      'Manual Sources',
      'Sentiment Analysis',
      'Smart Tags',
      '5000 Comments'
    ],
    isPopular: true
  },
  {
    tier: 'Enterprise',
    price: '199',
    period: 'month',
    description: 'For organisations with high-volume requirements',
    features: [
      'Social Integrations',
      'Helpdesk Integrations',
      'Manual Sources',
      'Sentiment Analysis',
      'Smart Tags',
      '10000 Comments'
    ]
  }
])

const emit = defineEmits(['select-plan', 'contact'])

const selectPlan = (plan) => {
  if (!isAuthenticated.value) {
    store.dispatch('auth/setLoginModal', true)
  } else {
    router.push('/billing')
  }
}

const contactUs = () => {
  window.open(
    'https://calendly.com/mhimimio/consultation?month=2024-12',
    '_blank'
  )
}
</script>