<template>
  <!-- Desktop filters -->

  <div class="space-y-4 mb-8 hidden md:block">
    <!-- Search Bar -->
    <!-- <div class="relative">
      <div
        class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
      >
        <svg
          class="h-5 w-5 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div>
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search comments..."
        class="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-200 placeholder-gray-500 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
      />
    </div> -->

    <!-- Filter Groups -->
    <div class="flex flex-wrap gap-4">
      <!-- Channel Filters -->
      <div class="space-y-2">
        <label class="block text-sm font-medium text-gray-400">Channels</label>
        <div class="flex flex-wrap gap-2">
          <button
            v-for="channel in channels"
            :key="channel.id"
            @click="toggleFilter('channel', channel.id)"
            :class="[
              'flex items-center space-x-2 rounded-full px-4 py-1.5 text-sm',
              isFilterActive('channel', channel.id)
                ? channel.activeClass
                : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
            ]"
          >
            <img :src="channel.icon" :alt="channel.label" class="h-4 w-4" />
            <span>{{ channel.label }}</span>
          </button>
        </div>
      </div>

      <!-- Sentiment Filters -->
      <div class="space-y-2">
        <label class="block text-sm font-medium text-gray-400">Sentiment</label>
        <div class="flex flex-wrap gap-2">
          <button
            v-for="sentiment in sentiments"
            :key="sentiment.id"
            @click="toggleFilter('sentiment', sentiment.id)"
            :class="[
              'flex items-center space-x-2 rounded-full px-4 py-1.5 text-sm',
              isFilterActive('sentiment', sentiment.id)
                ? sentiment.activeClass
                : 'bg-gray-800 hover:bg-gray-700',
              sentiment.id === 'positive'
                ? 'bg-green-500/10 text-green-400'
                : sentiment.id === 'negative'
                ? 'bg-red-500/10 text-red-400'
                : 'bg-gray-300/10 text-grey-300'
            ]"
          >
            <svg
              v-if="sentiment.id == 'positive'"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_5447_50)">
                <path
                  d="M6.9993 13.7198C3.29392 13.7198 0.279297 10.7052 0.279297 6.99979C0.279297 3.29441 3.29392 0.279785 6.9993 0.279785C10.7047 0.279785 13.7193 3.29441 13.7193 6.99979C13.7193 10.7052 10.7047 13.7198 6.9993 13.7198ZM6.9993 1.11979C3.75704 1.11979 1.1193 3.75753 1.1193 6.99979C1.1193 10.242 3.75704 12.8798 6.9993 12.8798C10.2416 12.8798 12.8793 10.242 12.8793 6.99979C12.8793 3.75753 10.2416 1.11979 6.9993 1.11979ZM10.7351 8.44767C10.8382 8.24117 10.7548 7.99154 10.5493 7.88682C10.3433 7.7828 10.0913 7.86471 9.98508 8.06939C9.95428 8.12847 9.20822 9.51979 6.9993 9.51979C4.7957 9.51979 4.04796 8.13533 4.0138 8.06995C3.90978 7.86359 3.65792 7.78001 3.45142 7.88417C3.24394 7.98791 3.1598 8.24019 3.26354 8.44767C3.30274 8.52579 4.25124 10.3598 6.9993 10.3598C9.74736 10.3598 10.6959 8.52579 10.7351 8.44767ZM9.0993 6.15979C8.63604 6.15979 8.2593 5.78305 8.2593 5.31979C8.2593 4.85653 8.63604 4.47979 9.0993 4.47979C9.56256 4.47979 9.9393 4.85653 9.9393 5.31979C9.9393 5.78305 9.56256 6.15979 9.0993 6.15979ZM4.8993 6.15979C4.43604 6.15979 4.0593 5.78305 4.0593 5.31979C4.0593 4.85653 4.43604 4.47979 4.8993 4.47979C5.36256 4.47979 5.7393 4.85653 5.7393 5.31979C5.7393 5.78305 5.36256 6.15979 4.8993 6.15979Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_5447_50">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <svg
              v-if="sentiment.id == 'negative'"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_5447_29)">
                <path
                  d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM7 13.2432C5.28512 13.2432 3.72968 12.5481 2.6002 11.4251C2.14917 10.9766 1.76629 10.4597 1.46818 9.8917C1.01416 9.02667 0.756767 8.04287 0.756767 7C0.756767 3.55747 3.55747 0.756767 7 0.756767C8.63287 0.756767 10.121 1.38715 11.2347 2.41702C11.8121 2.95092 12.2889 3.59209 12.6328 4.30875C13.0239 5.12412 13.2432 6.03692 13.2432 7C13.2432 10.4425 10.4425 13.2432 7 13.2432Z"
                  fill="currentColor"
                />
                <path
                  d="M7.73948 8.8734C8.56974 9.04703 9.31554 9.51382 9.8395 10.1878L10.4369 9.7233C9.80304 8.90788 8.90002 8.34296 7.89438 8.13262C6.27702 7.79463 4.57661 8.41887 3.5625 9.7233L4.16001 10.1878C4.99795 9.10989 6.40302 8.59387 7.73948 8.8734Z"
                  fill="currentColor"
                />
                <path
                  d="M4.66692 6.19615C5.08487 6.19615 5.42369 5.85734 5.42369 5.43938C5.42369 5.02143 5.08487 4.68262 4.66692 4.68262C4.24897 4.68262 3.91016 5.02143 3.91016 5.43938C3.91016 5.85734 4.24897 6.19615 4.66692 6.19615Z"
                  fill="currentColor"
                />
                <path
                  d="M9.34856 6.19615C9.76651 6.19615 10.1053 5.85734 10.1053 5.43938C10.1053 5.02143 9.76651 4.68262 9.34856 4.68262C8.93061 4.68262 8.5918 5.02143 8.5918 5.43938C8.5918 5.85734 8.93061 6.19615 9.34856 6.19615Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_5447_29">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              v-if="sentiment.id == 'neutral'"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_5447_2)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.728516 7.00008C0.728516 3.5368 3.53606 0.729248 6.99935 0.729248C10.4627 0.729248 13.2702 3.5368 13.2702 7.00008C13.2702 10.4634 10.4627 13.2709 6.99935 13.2709C3.53606 13.2709 0.728516 10.4634 0.728516 7.00008ZM6.99935 1.60425C4.01931 1.60425 1.60352 4.02004 1.60352 7.00008C1.60352 9.9801 4.01931 12.3959 6.99935 12.3959C9.97937 12.3959 12.3952 9.9801 12.3952 7.00008C12.3952 4.02004 9.97937 1.60425 6.99935 1.60425Z"
                  fill="currentColor"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.04102 4.8125C9.28263 4.8125 9.47852 5.00838 9.47852 5.25V5.54167C9.47852 5.78329 9.28263 5.97917 9.04102 5.97917C8.7994 5.97917 8.60352 5.78329 8.60352 5.54167V5.25C8.60352 5.00838 8.7994 4.8125 9.04102 4.8125Z"
                  fill="currentColor"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.95703 4.8125C5.19865 4.8125 5.39453 5.00838 5.39453 5.25V5.54167C5.39453 5.78329 5.19865 5.97917 4.95703 5.97917C4.71541 5.97917 4.51953 5.78329 4.51953 5.54167V5.25C4.51953 5.00838 4.71541 4.8125 4.95703 4.8125Z"
                  fill="currentColor"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.51953 9.04175C4.51953 8.80013 4.71541 8.60425 4.95703 8.60425H9.04036C9.28198 8.60425 9.47786 8.80013 9.47786 9.04175C9.47786 9.28336 9.28198 9.47925 9.04036 9.47925H4.95703C4.71541 9.47925 4.51953 9.28336 4.51953 9.04175Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_5447_2">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>{{ sentiment.label }}</span>
          </button>
        </div>
      </div>

      <!-- intent -->

      <div class="space-y-2">
        <label class="block text-sm font-medium text-gray-400">Intents</label>
        <div class="flex flex-wrap gap-2">
          <button
            v-for="intent in intents"
            :key="intent.id"
            @click="toggleFilter('intent', intent.id)"
            :class="[
              'flex items-center space-x-2 rounded-full px-4 py-1.5 text-sm',
              isFilterActive('intent', intent.id)
                ? intent.activeClass
                : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
            ]"
          >
            <svg
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
              />
            </svg>
            <span>{{ intent.label }}</span>
          </button>
        </div>
      </div>
      <!-- Tags Filters -->
      <div class="space-y-2">
        <label class="block text-sm font-medium text-gray-400">Tags</label>
        <div class="flex flex-wrap gap-2">
          <button
            v-for="tag in tags"
            :key="tag.id"
            @click="toggleFilter('tag', tag.id)"
            :class="[
              'flex items-center space-x-2 rounded-full px-4 py-1.5 text-sm',
              isFilterActive('tag', tag.id)
                ? tag.activeClass
                : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
            ]"
          >
            <svg
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
              />
            </svg>
            <span>{{ tag.label }}</span>
          </button>
        </div>
      </div>
    </div>

    <!-- Active Filters -->
    <div v-if="hasActiveFilters" class="flex items-center gap-2">
      <div class="text-sm text-gray-400">Active filters:</div>
      <div class="flex flex-wrap gap-2">
        <span
          v-for="filter in activeFiltersArray"
          :key="`${filter.type}-${filter.id}`"
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-500/10 text-indigo-400"
        >
          {{ filter.label }}
          <button
            @click="removeFilter(filter.type, filter.id)"
            class="ml-1.5 inline-flex items-center justify-center"
          >
            <svg class="w-3 h-3" viewBox="0 0 20 20" fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </span>
        <button
          @click="clearAllFilters"
          class="text-xs text-gray-400 hover:text-gray-300"
        >
          Clear all
        </button>
      </div>
    </div>
  </div>
  <!-- Mobile Filter Button & Modal -->
  <div class="md:hidden mb-8">
    <!-- Filter Button -->
    <button
      @click="isFilterModalOpen = true"
      class="w-full flex items-center justify-between px-4 py-2 bg-gray-800 rounded-lg text-gray-200 hover:bg-gray-700"
    >
      <div class="flex items-center gap-2">
        <svg
          class="w-5 h-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
          />
        </svg>
        <span>Filters</span>
      </div>
      <div
        v-if="activeFilterCount"
        class="bg-indigo-500 text-white text-xs px-2 py-0.5 rounded-full"
      >
        {{ activeFilterCount }}
      </div>
    </button>

    <!-- Filter Modal -->
    <div
      v-if="isFilterModalOpen"
      class="fixed inset-0 z-50 overflow-y-auto"
      @click.self="closeFilterModal"
    >
      <!-- Backdrop -->
      <div
        class="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
      ></div>

      <!-- Modal Content -->
      <div class="relative min-h-screen md:h-auto">
        <div class="relative bg-gray-900 h-full md:h-auto">
          <!-- Modal Header -->
          <div
            class="flex items-center justify-between px-4 py-3 border-b border-gray-800"
          >
            <h3 class="text-lg font-medium text-gray-200">Filters</h3>
            <button
              @click="closeFilterModal"
              class="p-1 hover:bg-gray-800 rounded-full"
            >
              <svg
                class="w-5 h-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>

          <!-- Modal Body -->
          <div class="p-4 space-y-6">
            <!-- Search -->
            <div class="relative">
              <div
                class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
              >
                <svg
                  class="h-5 w-5 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <input
                type="text"
                v-model="searchQuery"
                placeholder="Search comments..."
                class="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-200 placeholder-gray-500 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
              />
            </div>

            <!-- Filter Groups -->
            <div class="space-y-6">
              <!-- Channels -->
              <div class="space-y-3">
                <label class="block text-sm font-medium text-gray-400"
                  >Channels</label
                >
                <div class="grid grid-cols-2 gap-2">
                  <button
                    v-for="channel in channels"
                    :key="channel.id"
                    @click="toggleFilter('channel', channel.id)"
                    :class="[
                      'flex items-center justify-center gap-2 rounded-lg px-4 py-2 text-sm',
                      isFilterActive('channel', channel.id)
                        ? channel.activeClass
                        : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                    ]"
                  >
                    <img
                      :src="channel.icon"
                      :alt="channel.label"
                      class="h-4 w-4"
                    />
                    <span>{{ channel.label }}</span>
                  </button>
                </div>
              </div>

              <!-- Sentiments -->
              <div class="space-y-3">
                <label class="block text-sm font-medium text-gray-400"
                  >Sentiment</label
                >
                <div class="grid grid-cols-2 gap-2">
                  <button
                    v-for="sentiment in sentiments"
                    :key="sentiment.id"
                    @click="toggleFilter('sentiment', sentiment.id)"
                    :class="[
                      'flex items-center justify-center gap-2 rounded-lg px-4 py-2 text-sm',
                      isFilterActive('sentiment', sentiment.id)
                        ? sentiment.activeClass
                        : 'bg-gray-800 hover:bg-gray-700',
                      sentiment.id === 'positive'
                        ? 'bg-green-500/10 text-green-400'
                        : sentiment.id === 'negative'
                        ? 'bg-red-500/10 text-red-400'
                        : 'bg-gray-300/10 text-grey-300'
                    ]"
                  >
                    <svg
                      v-if="sentiment.id == 'positive'"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_5447_50)">
                        <path
                          d="M6.9993 13.7198C3.29392 13.7198 0.279297 10.7052 0.279297 6.99979C0.279297 3.29441 3.29392 0.279785 6.9993 0.279785C10.7047 0.279785 13.7193 3.29441 13.7193 6.99979C13.7193 10.7052 10.7047 13.7198 6.9993 13.7198ZM6.9993 1.11979C3.75704 1.11979 1.1193 3.75753 1.1193 6.99979C1.1193 10.242 3.75704 12.8798 6.9993 12.8798C10.2416 12.8798 12.8793 10.242 12.8793 6.99979C12.8793 3.75753 10.2416 1.11979 6.9993 1.11979ZM10.7351 8.44767C10.8382 8.24117 10.7548 7.99154 10.5493 7.88682C10.3433 7.7828 10.0913 7.86471 9.98508 8.06939C9.95428 8.12847 9.20822 9.51979 6.9993 9.51979C4.7957 9.51979 4.04796 8.13533 4.0138 8.06995C3.90978 7.86359 3.65792 7.78001 3.45142 7.88417C3.24394 7.98791 3.1598 8.24019 3.26354 8.44767C3.30274 8.52579 4.25124 10.3598 6.9993 10.3598C9.74736 10.3598 10.6959 8.52579 10.7351 8.44767ZM9.0993 6.15979C8.63604 6.15979 8.2593 5.78305 8.2593 5.31979C8.2593 4.85653 8.63604 4.47979 9.0993 4.47979C9.56256 4.47979 9.9393 4.85653 9.9393 5.31979C9.9393 5.78305 9.56256 6.15979 9.0993 6.15979ZM4.8993 6.15979C4.43604 6.15979 4.0593 5.78305 4.0593 5.31979C4.0593 4.85653 4.43604 4.47979 4.8993 4.47979C5.36256 4.47979 5.7393 4.85653 5.7393 5.31979C5.7393 5.78305 5.36256 6.15979 4.8993 6.15979Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5447_50">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <svg
                      v-if="sentiment.id == 'negative'"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_5447_29)">
                        <path
                          d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM7 13.2432C5.28512 13.2432 3.72968 12.5481 2.6002 11.4251C2.14917 10.9766 1.76629 10.4597 1.46818 9.8917C1.01416 9.02667 0.756767 8.04287 0.756767 7C0.756767 3.55747 3.55747 0.756767 7 0.756767C8.63287 0.756767 10.121 1.38715 11.2347 2.41702C11.8121 2.95092 12.2889 3.59209 12.6328 4.30875C13.0239 5.12412 13.2432 6.03692 13.2432 7C13.2432 10.4425 10.4425 13.2432 7 13.2432Z"
                          fill="currentColor"
                        />
                        <path
                          d="M7.73948 8.8734C8.56974 9.04703 9.31554 9.51382 9.8395 10.1878L10.4369 9.7233C9.80304 8.90788 8.90002 8.34296 7.89438 8.13262C6.27702 7.79463 4.57661 8.41887 3.5625 9.7233L4.16001 10.1878C4.99795 9.10989 6.40302 8.59387 7.73948 8.8734Z"
                          fill="currentColor"
                        />
                        <path
                          d="M4.66692 6.19615C5.08487 6.19615 5.42369 5.85734 5.42369 5.43938C5.42369 5.02143 5.08487 4.68262 4.66692 4.68262C4.24897 4.68262 3.91016 5.02143 3.91016 5.43938C3.91016 5.85734 4.24897 6.19615 4.66692 6.19615Z"
                          fill="currentColor"
                        />
                        <path
                          d="M9.34856 6.19615C9.76651 6.19615 10.1053 5.85734 10.1053 5.43938C10.1053 5.02143 9.76651 4.68262 9.34856 4.68262C8.93061 4.68262 8.5918 5.02143 8.5918 5.43938C8.5918 5.85734 8.93061 6.19615 9.34856 6.19615Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5447_29">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <svg
                      v-if="sentiment.id == 'neutral'"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_5447_2)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.728516 7.00008C0.728516 3.5368 3.53606 0.729248 6.99935 0.729248C10.4627 0.729248 13.2702 3.5368 13.2702 7.00008C13.2702 10.4634 10.4627 13.2709 6.99935 13.2709C3.53606 13.2709 0.728516 10.4634 0.728516 7.00008ZM6.99935 1.60425C4.01931 1.60425 1.60352 4.02004 1.60352 7.00008C1.60352 9.9801 4.01931 12.3959 6.99935 12.3959C9.97937 12.3959 12.3952 9.9801 12.3952 7.00008C12.3952 4.02004 9.97937 1.60425 6.99935 1.60425Z"
                          fill="currentColor"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.04102 4.8125C9.28263 4.8125 9.47852 5.00838 9.47852 5.25V5.54167C9.47852 5.78329 9.28263 5.97917 9.04102 5.97917C8.7994 5.97917 8.60352 5.78329 8.60352 5.54167V5.25C8.60352 5.00838 8.7994 4.8125 9.04102 4.8125Z"
                          fill="currentColor"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.95703 4.8125C5.19865 4.8125 5.39453 5.00838 5.39453 5.25V5.54167C5.39453 5.78329 5.19865 5.97917 4.95703 5.97917C4.71541 5.97917 4.51953 5.78329 4.51953 5.54167V5.25C4.51953 5.00838 4.71541 4.8125 4.95703 4.8125Z"
                          fill="currentColor"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.51953 9.04175C4.51953 8.80013 4.71541 8.60425 4.95703 8.60425H9.04036C9.28198 8.60425 9.47786 8.80013 9.47786 9.04175C9.47786 9.28336 9.28198 9.47925 9.04036 9.47925H4.95703C4.71541 9.47925 4.51953 9.28336 4.51953 9.04175Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5447_2">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>{{ sentiment.label }}</span>
                  </button>
                </div>
              </div>

              <!-- Tags -->
              <div class="space-y-3">
                <label class="block text-sm font-medium text-gray-400"
                  >Tags</label
                >
                <div class="grid grid-cols-2 gap-2">
                  <button
                    v-for="tag in tags"
                    :key="tag.id"
                    @click="toggleFilter('tag', tag.id)"
                    :class="[
                      'flex items-center justify-center gap-2 rounded-lg px-4 py-2 text-sm',
                      isFilterActive('tag', tag.id)
                        ? tag.activeClass
                        : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                    ]"
                  >
                    <svg
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                      />
                    </svg>
                    <span>{{ tag.label }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal Footer -->
          <div class="border-t border-gray-800 px-4 py-3 flex justify-between">
            <button
              @click="clearAllFilters"
              class="text-sm text-gray-400 hover:text-gray-300"
            >
              Clear all filters
            </button>
            <button
              @click="closeFilterModal"
              class="px-4 py-2 bg-indigo-600 text-white text-sm font-medium rounded-lg hover:bg-indigo-500"
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
const comments = computed(() => store.getters['comments/comments'])
const platformIcons = computed(() => store.getters['comments/platformIcons'])

const store = useStore()

const searchQuery = ref('')

// Filter state
const activeFilters = ref({
  channel: new Set(),
  sentiment: new Set(),
  tag: new Set(),
  intent: new Set()
})

// Filter definitions
const channels = [
  {
    id: 'facebook',
    label: 'Facebook',
    icon: platformIcons.value.facebook,
    activeClass: 'bg-blue-500/20 text-blue-400'
  },
  {
    id: 'instagram',
    label: 'Instagram',
    icon: platformIcons.value.instagram,
    activeClass: 'bg-pink-500/20 text-pink-400'
  },
  {
    id: 'youtube',
    label: 'YouTube',
    icon: platformIcons.value.youtube,
    activeClass: 'bg-red-500/20 text-red-400'
  },
  {
    id: 'zendesk',
    label: 'Zendesk',
    icon: platformIcons.value.zendesk,
    activeClass: 'bg-grey-500/20 text-red-400'
  }
]

const sentiments = [
  {
    id: 'positive',
    label: 'Positive',
    icon: 'ThumbsUpIcon',
    activeClass: 'bg-green-500/20 text-green-400'
  },
  {
    id: 'negative',
    label: 'Negative',
    icon: 'ThumbsDownIcon',
    activeClass: 'bg-red-500/20 text-red-400'
  },
  {
    id: 'neutral',
    label: 'Neutral',
    icon: '',
    activeClass: 'bg-grey-500/20 text-red-400'
  }
]

const tags = [
  {
    id: 'app stability',
    label: 'Bug',
    icon: 'BugIcon',
    activeClass: 'bg-red-500/20 text-red-400'
  },
  {
    id: 'feature suggestion',
    label: 'Feature Suggestion',
    icon: 'LightbulbIcon',
    activeClass: 'bg-purple-500/20 text-purple-400'
  },
  {
    id: 'user experience',
    label: 'UX',
    icon: 'BugIcon',
    activeClass: 'bg-blue-500/20 text-blue-400'
  },
  {
    id: 'customer service',
    label: 'Service',
    icon: 'BugIcon',
    activeClass: 'bg-blue-500/20 text-blue-400'
  },
  {
    id: 'shipping',
    label: 'Shipping',
    icon: 'BugIcon',
    activeClass: 'bg-blue-500/20 text-blue-400'
  }
]

const intents = [
  {
    id: 'criticising',
    label: 'Criticising',
    icon: 'BugIcon',
    activeClass: 'bg-red-500/20 text-red-400'
  },
  {
    id: 'seeking help',
    label: 'Seeking Help',
    icon: 'LightbulbIcon',
    activeClass: 'bg-purple-500/20 text-purple-400'
  },
  {
    id: 'reporting problem',
    label: 'Reporting Problem',
    icon: 'LightbulbIcon',
    activeClass: 'bg-purple-500/20 text-purple-400'
  },
  {
    id: 'requesting refund',
    label: 'Requesting Refund',
    icon: 'BugIcon',
    activeClass: 'bg-blue-500/20 text-blue-400'
  },
  {
    id: 'giving praise',
    label: 'Giving Praise',
    icon: 'BugIcon',
    activeClass: 'bg-blue-500/20 text-blue-400'
  }
  // {
  //   id: 'shipping',
  //   label: 'Shipping',
  //   icon: 'BugIcon',
  //   activeClass: 'bg-blue-500/20 text-blue-400'
  // }
]

// Mobile

const isFilterModalOpen = ref(false)
const tempFilters = ref({
  channel: new Set(),
  sentiment: new Set(),
  tag: new Set()
})

// Computed for active filter count
const activeFilterCount = computed(() => {
  return Object.values(activeFilters.value).reduce(
    (acc, set) => acc + set.size,
    0
  )
})

// Methods for modal handling
const closeFilterModal = () => {
  isFilterModalOpen.value = false
}

const openFilterModal = () => {
  // Clone current filters to temp filters
  Object.keys(tempFilters.value).forEach((key) => {
    tempFilters.value[key] = new Set(activeFilters.value[key])
  })
  isFilterModalOpen.value = true
}

const applyFilters = () => {
  // Apply temp filters to active filters
  Object.keys(activeFilters.value).forEach((key) => {
    activeFilters.value[key] = new Set(tempFilters.value[key])
  })
  closeFilterModal()
}

// Desktop Filter functions
const toggleFilter = (type, id) => {
  if (activeFilters.value[type].has(id)) {
    activeFilters.value[type].delete(id)
  } else {
    activeFilters.value[type].add(id)
  }
}

const isFilterActive = (type, id) => {
  return activeFilters.value[type].has(id)
}

const hasActiveFilters = computed(() => {
  return Object.values(activeFilters.value).some((set) => set.size > 0)
})

const activeFiltersArray = computed(() => {
  const filters = []
  for (const [type, ids] of Object.entries(activeFilters.value)) {
    for (const id of ids) {
      let label
      switch (type) {
        case 'channel':
          label = channels.find((c) => c.id === id)?.label
          break
        case 'sentiment':
          label = sentiments.find((s) => s.id === id)?.label
          break
        case 'intent':
          label = intents.find((u) => u.id === id)?.label
          break
        case 'tag':
          label = tags.find((t) => t.id === id)?.label
          break
      }
      if (label) {
        filters.push({ type, id, label })
      }
    }
  }
  return filters
})

const removeFilter = (type, id) => {
  activeFilters.value[type].delete(id)
}

const clearAllFilters = () => {
  Object.keys(activeFilters.value).forEach((type) => {
    activeFilters.value[type].clear()
  })
}

const filteredComments = computed(() => {
  let filtered = comments.value

  // Apply search filter
  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase()
    filtered = filtered.filter((comment) => comment.translation.includes(query))
  }

  // Apply channel filters
  if (activeFilters.value.channel.size > 0) {
    filtered = filtered.filter((comment) =>
      activeFilters.value.channel.has(comment.platform)
    )
  }

  // Apply sentiment filters
  if (activeFilters.value.sentiment.size > 0) {
    filtered = filtered.filter((comment) =>
      activeFilters.value.sentiment.has(comment.sentiment)
    )
  }

  if (activeFilters.value.intent.size > 0) {
    filtered = filtered.filter((comment) =>
      activeFilters.value.intent.has(comment.intent)
    )
  }

  // Apply tag filters if tags exist in data
  if (activeFilters.value.tag.size > 0) {
    console.log(activeFilters.value.tag)
    filtered = filtered.filter((comment) =>
      comment.systemTags?.some((tag) => activeFilters.value.tag.has(tag))
    )
  }

  return filtered
})

watch(filteredComments, (newValue, oldValue) => {
  store.dispatch('comments/setFilteredComments', newValue)
})
</script>


