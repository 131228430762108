<template>
  <div class="flex min-h-screen bg-gray-950">
    <!-- Mobile Menu Button -->
    <div class="fixed left-0 top-0 z-20 m-4 lg:hidden">
      <button
        @click="isSidebarOpen = !isSidebarOpen"
        class="rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white"
      >
        <svg
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            v-if="isSidebarOpen"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
          <path
            v-else
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <!-- Sidebar -->
    <aside
      :class="`${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      } fixed inset-y-0 left-0 z-10 w-64 bg-gray-900 p-4 transition-transform duration-300 ease-in-out lg:static lg:translate-x-0`"
    >
      <div class="flex items-center gap-3 px-3 py-0">
        <div
          class="flex h-8 w-8 items-center justify-center rounded bg-indigo-600"
        >
          <svg
            class="h-5 w-5 text-white"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
            />
          </svg>
        </div>
        <div>
          <h1 class="text-xl font-bold gradient-text tracking-tight">
            Zadabra
          </h1>
          <p class="text-xs text-gray-400">Magical Insights</p>
        </div>
      </div>
      <!-- <div class="mb-8 ml-12">
        <h1 class="text-xl font-bold text-indigo-500">Zadabra</h1>
      </div> -->
      <SidebarNav @navigate="closeMobileMenu" />
    </aside>

    <!-- Main Content -->
    <div class="flex-1">
      <!-- Top Header -->
      <header
        class="flex h-16 items-center justify-between border-b border-gray-800 bg-gray-900 px-6"
      >
        <div class="flex items-center space-x-12"></div>
        <div class="flex items-center space-x-4">
          <button class="rounded-full bg-gray-800 p-2">
            <svg
              class="h-5 w-5 text-gray-300"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
          </button>
          <div class="relative">
            <button
              @click="isDropdownOpen = !isDropdownOpen"
              class="flex items-center focus:outline-none"
            >
              <img :src="avatar" alt="Profile" class="h-8 w-8 rounded-full" />
            </button>

            <!-- Dropdown Menu -->
            <div
              v-if="isDropdownOpen"
              class="absolute right-0 z-50 mt-2 w-48 rounded-md bg-gray-800 py-1 shadow-lg ring-1 ring-black ring-opacity-5"
            >
              <div
                class="border-b border-gray-700 px-4 py-2"
                v-if="currentUser"
              >
                <p class="text-sm text-gray-200">{{ currentUser.name }}</p>
                <p class="text-xs text-gray-400">{{ currentUser.email }}</p>
              </div>
              <router-link
                to="/dashboard"
                class="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700"
                @click="closeDropdown"
              >
                Profile
              </router-link>
              <button
                @click="handleLogout"
                class="block w-full px-4 py-2 text-left text-sm text-gray-300 hover:bg-gray-700"
              >
                Log out
              </button>
            </div>
          </div>
        </div>
      </header>

      <!-- Page Content -->
      <main class="p-6">
        <slot></slot>
      </main>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import SidebarNav from '@/components/layouts/SidebarNav.vue'

const store = useStore()
const router = useRouter()
const isDropdownOpen = ref(false)
const isSidebarOpen = ref(false)
const currentUser = computed(() => store.getters['auth/currentUser'])

const tokenizeName = (name) => name.replace(/\s+/g, '+')

const avatar = computed(() => {
  if (!currentUser.value) return
  let tokenisedName = tokenizeName(currentUser.value.name)
  return 'https://ui-avatars.com/api/?name=' + tokenisedName
})

const handleLogout = async () => {
  await store.dispatch('auth/handleLogout')
  closeDropdown()
  router.push('/')
}

const closeDropdown = () => {
  isDropdownOpen.value = false
}

const closeMobileMenu = () => {
  isSidebarOpen.value = false
}

// Close dropdown when clicking outside
const handleClickOutside = (event) => {
  if (!event.target.closest('.relative')) {
    isDropdownOpen.value = false
  }
}

// Add and remove event listener
onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>