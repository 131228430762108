<script setup>
import { ref, computed, onMounted } from 'vue'
import BarChart from './BarChart.vue'
import { useStore } from 'vuex'
import { Calendar } from 'lucide-vue-next'

const store = useStore()
const growingTerms = computed(() => store.getters['analytics/growingTerms'])
const loading = ref(true)
const filterType = ref('intent')

const filterOptions = [
  { value: 'intent', label: 'Intent' },
  { value: 'sentiment', label: 'Sentiment' },
  { value: 'tag', label: 'Tag' },
  { value: 'keyword', label: 'Keyword' },
  { value: 'platform', label: 'Platform' }
]

const dateRange = ref({
  start: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
  end: new Date()
})

const getTrends = async () => {
  try {
    loading.value = true
    const params = {
      start_date: dateRange.value.start.toISOString().split('T')[0],
      end_date: dateRange.value.end.toISOString().split('T')[0],
      type: filterType.value
    }

    const response = await store.dispatch('analytics/getTrends', params)
    data.value = growingTerms.value.data.data.spikes.map((item) => ({
      ...item,
      avgGrowth: parseFloat(item.avgGrowth),
      count: parseFloat(item.count || 0)
    }))
    chartData.value.labels = growingTerms.value.data.data.spikes.map(
      (item) => item.term
    )
    chartData.value.datasets[0].data = growingTerms.value.data.data.spikes.map(
      (item) => item.avgGrowth
    )
    loading.value = false
  } catch (error) {
    console.error('Error:', error)
  }
}

const handleDateChange = () => {
  const startDate =
    dateRange.value.start instanceof Date
      ? dateRange.value.start
      : new Date(dateRange.value.start)

  const endDate =
    dateRange.value.end instanceof Date
      ? dateRange.value.end
      : new Date(dateRange.value.end)

  console.log('Start date:', startDate.toISOString().split('T')[0])
  console.log('End date:', endDate.toISOString().split('T')[0])

  getTrends()
}

const handleFilterChange = () => {
  getTrends()
}

let data = ref([])

const avgGrowth = computed(() =>
  parseFloat(
    data.value.reduce((acc, curr) => acc + parseFloat(curr.count || 0), 0) /
      data.value.length
  ).toFixed(2)
)

const chartData = ref({
  labels: data.value.map((item) => item.term),
  datasets: [
    {
      label: 'Growth %',
      data: data.value.map((item) => item.avgGrowth),
      backgroundColor: '#3b82f6'
    }
  ]
})

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      labels: {
        color: '#fff'
      }
    }
  },
  scales: {
    y: {
      ticks: { color: '#fff' },
      grid: {
        color: '#374151'
      }
    },
    x: {
      ticks: { color: '#fff' },
      grid: {
        color: '#374151'
      }
    }
  }
}

function format(no) {
  return parseFloat(no).toFixed(2)
}

onMounted(() => {
  getTrends()
})
</script>

<template>
  <div v-if="loading"></div>
  <div v-else class="min-h-screen">
    <div class="flex justify-between items-center mb-8">
      <h2 class="text-xl font-semibold text-white">
        Trends (Last 7 Days vs Previous)
      </h2>

      <!-- Filters Container -->
      <div class="flex items-center gap-4">
        <!-- Type Filter -->
        <!-- Replace the existing select element and its container with this: -->
        <div class="flex items-center gap-2">
          <div class="relative">
            <select
              v-model="filterType"
              @change="handleFilterChange"
              class="appearance-none bg-gray-800 text-white px-4 py-2 pr-10 rounded-lg border border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent cursor-pointer hover:bg-gray-750"
            >
              <option
                v-for="option in filterOptions"
                :key="option.value"
                :value="option.value"
                class="bg-gray-800 hover:bg-gray-700"
              >
                {{ option.label }}
              </option>
            </select>
            <!-- Custom dropdown arrow -->
            <div
              class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none"
            >
              <svg
                class="w-4 h-4 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
          </div>
        </div>
        <!-- Date Range Filter -->
        <!-- <div class="flex items-center gap-4">
          <div class="flex items-center gap-2 bg-gray-800 px-4 py-2 rounded-lg">
            <Calendar class="w-5 h-5 text-gray-400" />
            <input
              type="date"
              v-model="dateRange.start"
              class="bg-transparent text-white border-none focus:outline-none"
              @change="handleDateChange"
            />
          </div>
          <span class="text-gray-400">to</span>
          <div class="flex items-center gap-2 bg-gray-800 px-4 py-2 rounded-lg">
            <Calendar class="w-5 h-5 text-gray-400" />
            <input
              type="date"
              v-model="dateRange.end"
              class="bg-transparent text-white border-none focus:outline-none"
              @change="handleDateChange"
            />
          </div>
        </div> -->
      </div>
    </div>

    <!-- Stats Cards -->
    <div class="grid grid-cols-1 md:grid-cols-1 gap-4 mb-6">
      <div class="bg-gray-900 rounded-lg p-4">
        <p class="text-sm text-gray-400">Total Comments</p>
        <p class="text-2xl font-bold text-white">
          {{ growingTerms.data.meta.totalCount }}
        </p>
      </div>
    </div>

    <!-- Chart -->
    <div class="bg-gray-900 rounded-lg p-4 mb-6">
      <h3 class="text-lg font-semibold text-white mb-4">Growth Trends</h3>
      <div class="h-[400px]">
        <BarChart :chart-data="chartData" :chart-options="chartOptions" />
      </div>
    </div>

    <!-- Terms List -->
    <div class="bg-gray-900 rounded-lg p-4">
      <h3 class="text-lg font-semibold text-white mb-4">
        Top {{ filterType.charAt(0).toUpperCase() + filterType.slice(1) }}s
      </h3>
      <div class="space-y-2">
        <div
          v-for="(item, index) in data"
          :key="item.term"
          class="flex items-center justify-between p-3 bg-gray-800 rounded-lg"
        >
          <div class="flex items-center gap-3">
            <span class="text-sm text-gray-400">#{{ index + 1 }}</span>
            <span class="font-medium text-white"
              >{{ item.term }} ({{ item.count }})</span
            >
          </div>
          <span class="font-bold text-blue-400"
            >+{{ item.avgGrowth.toFixed(2) }}%</span
          >
        </div>
      </div>
    </div>
  </div>
</template>