<template>
  <div class="py-24 px-6">
    <div class="max-w-4xl mx-auto text-center">
      <h2 class="text-4xl font-bold mb-6 tracking-tight">
        Ready to
        <br /><span class="gradient-text"
          >Turn Customer Feedback into Action?</span
        >
      </h2>
      <p class="text-xl text-gray-300 mb-10 leading-relaxed">
        {{ description }}
      </p>
      <div class="flex flex-wrap justify-center gap-5">
        <button
          @click="startTrial"
          class="bg-indigo-500 hover:bg-indigo-600 px-8 py-4 rounded-lg text-white font-medium transition-all duration-200 ease-out transform hover:translate-y-[-1px] hover:shadow-lg hover:shadow-indigo-500/25"
        >
          Try Free for 14 Days
        </button>
        <button
          @click="scheduleDemo"
          class="px-8 py-4 rounded-lg border border-gray-700 hover:border-indigo-500/50 text-gray-300 hover:text-white transition-all duration-200"
        >
          Schedule Demo
        </button>
      </div>
      <p class="mt-8 text-gray-500">
        No credit card required · 14-day free trial · Cancel anytime
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()
const showModal = computed(() => store.getters['auth/showLoginModal'])
const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])

const startTrial = () => {
  if (!isAuthenticated.value) {
    store.dispatch('auth/setLoginModal', true)
  } else {
    router.push('/billing')
  }
}
const description = 'Get started in 2 minutes, see insights in 10.'

const scheduleDemo = () => {
  window.open(
    'https://calendly.com/mhimimio/consultation?month=2024-12',
    '_blank'
  )
}
</script>