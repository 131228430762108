<template>
  <div class="bg-gradient-dark min-h-screen text-gray-100 antialiased">
    <NavBar v-if="isMarketingRoute" />
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import NavBar from '@/components/NavBar.vue'

const route = useRoute()
const isMarketingRoute = computed(() => route.path == '/')
</script>

<style>
.bg-gradient-dark {
  background: linear-gradient(to bottom right, #13131f, #0a0a12);
}
.feature-card {
  background: linear-gradient(
    145deg,
    rgba(38, 38, 56, 0.4) 0%,
    rgba(28, 28, 42, 0.4) 100%
  );
  backdrop-filter: blur(12px);
}
.accent-glow {
  box-shadow: 0 0 40px rgba(99, 102, 241, 0.1);
}
.gradient-border {
  border: double 1px transparent;
  background-image: linear-gradient(#13131f, #13131f),
    linear-gradient(to right, #818cf8, #6366f1);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
.gradient-text {
  background: linear-gradient(135deg, #818cf8, #6366f1);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.nav-blur {
  backdrop-filter: blur(16px);
  background: rgba(19, 19, 31, 0.85);
}
.hero-gradient {
  background: radial-gradient(
    70% 70% at 50% 100%,
    rgba(99, 102, 241, 0.15) 0%,
    rgba(19, 19, 31, 0) 100%
  );
}

.dark-swal {
  background: rgb(17, 24, 39) !important;
  color: #ffffff !important;
  border: 1px solid #211f26 !important;
}

.swal-confirm-btn {
  background: rgb(79, 70, 229) !important;
  color: white !important;
}

.swal2-title {
  color: #ffffff !important;
}

.swal2-html-container {
  color: #9ca3af !important;
}
:root {
  background-color: #111827;
}

body {
  background-color: #111827;
  min-height: 100vh;
  padding-bottom: env(safe-area-inset-bottom);
}

/* Spinner */

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: 818cf8;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.spinner {
  border: 4px solid rgb(17, 24, 39);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>