<template>
  <div class="relative pt-32 pb-24 px-6 hero-gradient">
    <div class="max-w-7xl mx-auto">
      <div class="grid lg:grid-cols-2 gap-16 items-center">
        <div class="max-w-2xl">
          <div
            class="inline-flex items-center px-4 py-2 rounded-full bg-indigo-500/10 border border-indigo-500/20 text-indigo-300 text-[15px] mb-8"
          >
            <span class="w-2 h-2 rounded-full bg-indigo-400 mr-2"></span>
            {{ badge }}
          </div>
          <h1 class="text-5xl font-bold mb-8 leading-tight tracking-tight">
            {{ headline.main }}
            <span class="gradient-text">{{ headline.highlight }}</span>
          </h1>
          <p class="text-xl leading-relaxed text-gray-300 mb-10">
            {{ description }}
          </p>
          <div class="flex flex-wrap gap-5">
            <button
              @click="startTrial"
              class="bg-indigo-500 hover:bg-indigo-600 px-8 py-4 rounded-lg text-white font-medium transition-all duration-200 ease-out transform hover:translate-y-[-1px] hover:shadow-lg hover:shadow-indigo-500/25"
            >
              Start Free Trial
            </button>
            <button
              @click="watchDemo"
              class="px-8 py-4 rounded-lg border border-gray-700 hover:border-indigo-500/50 text-gray-300 hover:text-white transition-all duration-200"
            >
              Schedule Demo
            </button>
          </div>
        </div>
        <div class="relative lg:mt-0 mt-8">
          <div
            class="absolute inset-0 bg-indigo-500 rounded-2xl blur-[100px] opacity-20"
          ></div>
          <div
            class="relative bg-gradient-dark p-2 rounded-2xl border border-white/10"
          >
            <img
              src="https://res.cloudinary.com/mioven/image/upload/v1733031650/Screenshot_2024-12-01_at_05.40.36.png"
              class="rounded-xl w-full shadow-2xl"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const showModal = computed(() => store.getters['auth/showLoginModal'])
const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])
const router = useRouter()

const badge =
  'The Feedback Analysis Platform for Product and Marketing Professionals'
// const headline = reactive({
//   main: "Transform Social Comments into ",
//   highlight: "Business Intelligence",
// });
const headline = reactive({
  main: 'All Your Customer Feedback in One',
  highlight: 'Intelligence Platform'
})
const description =
  'Capture and analyse customer feedback in real-time from any source. Connect social media, helpdesks, or CSV files, and transform them into actionable insights that drive impactful decisions.'
const startTrial = () => {
  if (!isAuthenticated.value) {
    store.dispatch('auth/setLoginModal', true)
  } else {
    router.push('/billing')
  }
}

const watchDemo = () => {
  window.open(
    'https://calendly.com/mhimimio/consultation?month=2024-12',
    '_blank'
  )
}
</script>