import api from '@/api'

const state = {
  channels: []
}

const getters = {
  channels: (state) => state.channels
}

const actions = {
  async getChannels({ commit }, data) {
    console.log(data)
    try {
      const response = await api.get('/channels')

      commit('SET_CHANNELS', response.data.channels)

      return response
    } catch (error) {
      console.error(
        'Failed to retrieve channels:',
        error.response?.data.meta.message || error.message
      )
      throw new Error(
        error.response?.data?.meta.message || 'Failed to retrieve channels.'
      )
    }
  },

  setChannels({ commit }, channels) {
    commit('SET_CHANNELS', channels)
  }
}

const mutations = {
  SET_CHANNELS(state, channels) {
    state.channels = channels
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
