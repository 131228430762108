<template>
  <div>
    <HeroSection />
    <FeatureSection />
    <IntegrationSection />
    <PricingCard
      @select-plan="handlePlanSelection"
      @contact="handleContactRequest"
    />
    <CTASection />
  </div>
</template>

<script setup>
import HeroSection from '@/components/HeroSection.vue'
import FeatureSection from '@/components/FeatureSection.vue'
import IntegrationSection from '@/components/IntegrationSection.vue'
import PricingCard from '@/components/PricingCard.vue'

import CTASection from '@/components/CTASection.vue'

const handlePlanSelection = (plan) => {
  console.log('Selected plan:', plan)
  // Handle plan selection
}

const handleContactRequest = () => {
  console.log('Contact request')
  // Handle contact request
}
</script>
