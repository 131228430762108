import api from '@/api'

const state = {
  selectedPlan: null,
  plans: [
    {
      tier: 'Basic',
      price: '29',
      period: 'month',
      description: 'Perfect for individuals and small teams',
      features: [
        'Up to 5 social accounts',
        'Basic analytics',
        'Schedule up to 30 posts',
        'Reply to comments',
        'Export basic reports'
      ]
    },
    {
      tier: 'Professional',
      price: '99',
      period: 'month',
      description: 'For growing businesses and marketing teams',
      features: [
        'Up to 15 social accounts',
        'Advanced analytics',
        'Unlimited scheduled posts',
        'Priority support',
        'Custom reports',
        'Team collaboration',
        'API access'
      ],
      isPopular: true
    },
    {
      tier: 'Enterprise',
      price: '299',
      period: 'month',
      description: 'Custom solutions for large organizations',
      features: [
        'Unlimited social accounts',
        'Custom analytics dashboard',
        'Dedicated account manager',
        '24/7 priority support',
        'Advanced security features',
        'Custom integrations',
        'SLA guarantee'
      ]
    }
  ]
}

const getters = {
  allPlans: (state) => state.plans,
  selectedPlan: (state) => state.selectedPlan
}

const actions = {
  selectPlan({ commit }, plan) {
    commit('SET_SELECTED_PLAN', plan)
  },

  async getBillingSession({ commit }, data) {
    console.log(data)
    try {
      const response = await api.post(
        '/users/' + data.userId + '/billing_session',
        data
      )

      commit('SET_BILLING_SESSION', response.data.url)

      return response
    } catch (error) {
      console.error(
        'Failed to retrieve session:',
        error.response?.data.meta.message || error.message
      )
      throw new Error(
        error.response?.data?.meta.message || 'Failed to retrieve session.'
      )
    }
  },

  async purchasePlan({ state, commit, rootGetters }) {
    try {
      if (!rootGetters['auth/isAuthenticated']) {
        throw new Error('Must be authenticated to purchase a plan')
      }

      // API call would go here
      const response = { success: true, plan: state.selectedPlan }
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  SET_SELECTED_PLAN(state, plan) {
    state.selectedPlan = plan
  },
  SET_BILLING_SESSION(state, billingSession) {
    state.billingSession = billingSession
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
